const jrverses = [
  {id: 1, ref: "Act1:1", verse: "The former treatise have I made, O Theophilus, of all that Jesus began both to do and teach,"},
  {id: 2, ref: "Act1:2", verse: "Until the day in which he was taken up, after that he through the Holy Ghost had given commandments unto the apostles whom he had chosen:"},
  {id: 3, ref: "Act1:3", verse: "To whom also he shewed himself alive after his passion by many infallible proofs, being seen of them forty days, and speaking of the things pertaining to the kingdom of God:"},
  {id: 4, ref: "Act1:4", verse: "And, being assembled together with them, commanded them that they should not depart from Jerusalem, but wait for the promise of the Father, which, saith he, ye have heard of me."},
  {id: 5, ref: "Act1:5", verse: "For John truly baptized with water; but ye shall be baptized with the Holy Ghost not many days hence."},
  {id: 6, ref: "Act1:6", verse: "When they therefore were come together, they asked of him, saying, Lord, wilt thou at this time restore again the kingdom to Israel?"},
  {id: 7, ref: "Act1:7", verse: "And he said unto them, It is not for you to know the times or the seasons, which the Father hath put in his own power."},
  {id: 8, ref: "Act1:8", verse: "But ye shall receive power, after that the Holy Ghost is come upon you: and ye shall be witnesses unto me both in Jerusalem, and in all Judaea, and in Samaria, and unto the uttermost part of the earth."},
  {id: 9, ref: "Act1:9", verse: "And when he had spoken these things, while they beheld, he was taken up; and a cloud received him out of their sight."},
  {id: 10, ref: "Act1:10", verse: "And while they looked stedfastly toward heaven as he went up, behold, two men stood by them in white apparel;"},
  {id: 11, ref: "Act1:11", verse: "Which also said, Ye men of Galilee, why stand ye gazing up into heaven? this same Jesus, which is taken up from you into heaven, shall so come in like manner as ye have seen him go into heaven."},
  {id: 12, ref: "Act1:12", verse: "Then returned they unto Jerusalem from the mount called Olivet, which is from Jerusalem a sabbath day's journey."},
  {id: 13, ref: "Act1:13", verse: "And when they were come in, they went up into an upper room, where abode both Peter, and James, and John, and Andrew, Philip, and Thomas, Bartholomew, and Matthew, James the son of Alphaeus, and Simon Zelotes, and Judas the brother of James."},
  {id: 14, ref: "Act1:14", verse: "These all continued with one accord in prayer and supplication, with the women, and Mary the mother of Jesus, and with his brethren."},
  {id: 15, ref: "Act2:1", verse: "And when the day of Pentecost was fully come, they were all with one accord in one place."},
  {id: 16, ref: "Act2:2", verse: "And suddenly there came a sound from heaven as of a rushing mighty wind, and it filled all the house where they were sitting."},
  {id: 17, ref: "Act2:3", verse: "And there appeared unto them cloven tongues like as of fire, and it sat upon each of them."},
  {id: 18, ref: "Act2:4", verse: "And they were all filled with the Holy Ghost, and began to speak with other tongues, as the Spirit gave them utterance."},
  {id: 19, ref: "Act2:5", verse: "And there were dwelling at Jerusalem Jews, devout men, out of every nation under heaven."},
  {id: 20, ref: "Act2:6", verse: "Now when this was noised abroad, the multitude came together, and were confounded, because that every man heard them speak in his own language."},
  {id: 21, ref: "Act2:7", verse: "And they were all amazed and marvelled, saying one to another, Behold, are not all these which speak Galilaeans?"},
  {id: 22, ref: "Act2:8", verse: "And how hear we every man in our own tongue, wherein we were born?"},
  {id: 23, ref: "Act2:9", verse: "Parthians, and Medes, and Elamites, and the dwellers in Mesopotamia, and in Judaea, and Cappadocia, in Pontus, and Asia,"},
  {id: 24, ref: "Act2:10", verse: "Phrygia, and Pamphylia, in Egypt, and in the parts of Libya about Cyrene, and strangers of Rome, Jews and proselytes,"},
  {id: 25, ref: "Act2:11", verse: "Cretes and Arabians, we do hear them speak in our tongues the wonderful works of God."},
  {id: 26, ref: "Act2:12", verse: "And they were all amazed, and were in doubt, saying one to another, What meaneth this?"},
  {id: 27, ref: "Act2:13", verse: "Others mocking said, These men are full of new wine."},
  {id: 28, ref: "Act2:14", verse: "But Peter, standing up with the eleven, lifted up his voice, and said unto them, Ye men of Judaea, and all ye that dwell at Jerusalem, be this known unto you, and hearken to my words:"},
  {id: 29, ref: "Act2:15", verse: "For these are not drunken, as ye suppose, seeing it is but the third hour of the day."},
  {id: 30, ref: "Act2:16", verse: "But this is that which was spoken by the prophet Joel;"},
  {id: 31, ref: "Act2:17", verse: "And it shall come to pass in the last days, saith God, I will pour out of my Spirit upon all flesh: and your sons and your daughters shall prophesy, and your young men shall see visions, and your old men shall dream dreams:"},
  {id: 32, ref: "Act2:18", verse: "And on my servants and on my handmaidens I will pour out in those days of my Spirit; and they shall prophesy:"},
  {id: 33, ref: "Act2:19", verse: "And I will shew wonders in heaven above, and signs in the earth beneath; blood, and fire, and vapour of smoke:"},
  {id: 34, ref: "Act2:20", verse: "The sun shall be turned into darkness, and the moon into blood, before that great and notable day of the Lord come:"},
  {id: 35, ref: "Act2:21", verse: "And it shall come to pass, that whosoever shall call on the name of the Lord shall be saved."},
  {id: 36, ref: "Act2:22", verse: "Ye men of Israel, hear these words; Jesus of Nazareth, a man approved of God among you by miracles and wonders and signs, which God did by him in the midst of you, as ye yourselves also know:"},
  {id: 37, ref: "Act2:23", verse: "Him, being delivered by the determinate counsel and foreknowledge of God, ye have taken, and by wicked hands have crucified and slain:"},
  {id: 38, ref: "Act2:24", verse: "Whom God hath raised up, having loosed the pains of death: because it was not possible that he should be holden of it."},
  {id: 39, ref: "Act2:25", verse: "For David speaketh concerning him, I foresaw the Lord always before my face, for he is on my right hand, that I should not be moved:"},
  {id: 40, ref: "Act2:26", verse: "Therefore did my heart rejoice, and my tongue was glad; moreover also my flesh shall rest in hope:"},
  {id: 41, ref: "Act2:27", verse: "Because thou wilt not leave my soul in hell, neither wilt thou suffer thine Holy One to see corruption."},
  {id: 42, ref: "Act2:28", verse: "Thou hast made known to me the ways of life; thou shalt make me full of joy with thy countenance."},
  {id: 43, ref: "Act2:29", verse: "Men and brethren, let me freely speak unto you of the patriarch David, that he is both dead and buried, and his sepulchre is with us unto this day."},
  {id: 44, ref: "Act2:30", verse: "Therefore being a prophet, and knowing that God had sworn with an oath to him, that of the fruit of his loins, according to the flesh, he would raise up Christ to sit on his throne;"},
  {id: 45, ref: "Act2:31", verse: "He seeing this before spake of the resurrection of Christ, that his soul was not left in hell, neither his flesh did see corruption."},
  {id: 46, ref: "Act2:32", verse: "This Jesus hath God raised up, whereof we all are witnesses."},
  {id: 47, ref: "Act2:33", verse: "Therefore being by the right hand of God exalted, and having received of the Father the promise of the Holy Ghost, he hath shed forth this, which ye now see and hear."},
  {id: 48, ref: "Act2:34", verse: "For David is not ascended into the heavens: but he saith himself, The LORD said unto my Lord, Sit thou on my right hand,"},
  {id: 49, ref: "Act2:35", verse: "Until I make thy foes thy footstool."},
  {id: 50, ref: "Act2:36", verse: "Therefore let all the house of Israel know assuredly, that God hath made that same Jesus, whom ye have crucified, both Lord and Christ."},
  {id: 51, ref: "Act2:37", verse: "Now when they heard this, they were pricked in their heart, and said unto Peter and to the rest of the apostles, Men and brethren, what shall we do?"},
  {id: 52, ref: "Act2:38", verse: "Then Peter said unto them, Repent, and be baptized every one of you in the name of Jesus Christ for the remission of sins, and ye shall receive the gift of the Holy Ghost."},
  {id: 53, ref: "Act2:39", verse: "For the promise is unto you, and to your children, and to all that are afar off, even as many as the Lord our God shall call."},
  {id: 54, ref: "Act2:40", verse: "And with many other words did he testify and exhort, saying, Save yourselves from this untoward generation."},
  {id: 55, ref: "Act2:41", verse: "Then they that gladly received his word were baptized: and the same day there were added unto them about three thousand souls."},
  {id: 56, ref: "Act2:42", verse: "And they continued stedfastly in the apostles' doctrine and fellowship, and in breaking of bread, and in prayers."},
  {id: 57, ref: "Act2:43", verse: "And fear came upon every soul: and many wonders and signs were done by the apostles."},
  {id: 58, ref: "Act2:44", verse: "And all that believed were together, and had all things common;"},
  {id: 59, ref: "Act2:45", verse: "And sold their possessions and goods, and parted them to all men, as every man had need."},
  {id: 60, ref: "Act2:46", verse: "And they, continuing daily with one accord in the temple, and breaking bread from house to house, did eat their meat with gladness and singleness of heart,"},
  {id: 61, ref: "Act2:47", verse: "Praising God, and having favour with all the people. And the Lord added to the church daily such as should be saved."},
  {id: 62, ref: "Act3:1", verse: "Now Peter and John went up together into the temple at the hour of prayer, being the ninth hour."},
  {id: 63, ref: "Act3:2", verse: "And a certain man lame from his mother's womb was carried, whom they laid daily at the gate of the temple which is called Beautiful, to ask alms of them that entered into the temple;"},
  {id: 64, ref: "Act3:3", verse: "Who seeing Peter and John about to go into the temple asked an alms."},
  {id: 65, ref: "Act3:4", verse: "And Peter, fastening his eyes upon him with John, said, Look on us."},
  {id: 66, ref: "Act3:5", verse: "And he gave heed unto them, expecting to receive something of them."},
  {id: 67, ref: "Act3:6", verse: "Then Peter said, Silver and gold have I none; but such as I have give I thee: In the name of Jesus Christ of Nazareth rise up and walk."},
  {id: 68, ref: "Act3:7", verse: "And he took him by the right hand, and lifted him up: and immediately his feet and ankle bones received strength."},
  {id: 69, ref: "Act3:8", verse: "And he leaping up stood, and walked, and entered with them into the temple, walking, and leaping, and praising God."},
  {id: 70, ref: "Act3:9", verse: "And all the people saw him walking and praising God:"},
  {id: 71, ref: "Act3:10", verse: "And they knew that it was he which sat for alms at the Beautiful gate of the temple: and they were filled with wonder and amazement at that which had happened unto him."},
  {id: 72, ref: "Act3:11", verse: "And as the lame man which was healed held Peter and John, all the people ran together unto them in the porch that is called Solomon's, greatly wondering."},
  {id: 73, ref: "Act4:1", verse: "And as they spake unto the people, the priests, and the captain of the temple, and the Sadducees, came upon them,"},
  {id: 74, ref: "Act4:2", verse: "Being grieved that they taught the people, and preached through Jesus the resurrection from the dead."},
  {id: 75, ref: "Act4:3", verse: "And they laid hands on them, and put them in hold unto the next day: for it was now eventide."},
  {id: 76, ref: "Act4:4", verse: "Howbeit many of them which heard the word believed; and the number of the men was about five thousand."},
  {id: 77, ref: "Act4:5", verse: "And it came to pass on the morrow, that their rulers, and elders, and scribes,"},
  {id: 78, ref: "Act4:6", verse: "And Annas the high priest, and Caiaphas, and John, and Alexander, and as many as were of the kindred of the high priest, were gathered together at Jerusalem."},
  {id: 79, ref: "Act4:7", verse: "And when they had set them in the midst, they asked, By what power, or by what name, have ye done this?"},
  {id: 80, ref: "Act4:8", verse: "Then Peter, filled with the Holy Ghost, said unto them, Ye rulers of the people, and elders of Israel,"},
  {id: 81, ref: "Act4:9", verse: "If we this day be examined of the good deed done to the impotent man, by what means he is made whole;"},
  {id: 82, ref: "Act4:10", verse: "Be it known unto you all, and to all the people of Israel, that by the name of Jesus Christ of Nazareth, whom ye crucified, whom God raised from the dead, even by him doth this man stand here before you whole."},
  {id: 83, ref: "Act4:11", verse: "This is the stone which was set at nought of you builders, which is become the head of the corner."},
  {id: 84, ref: "Act4:12", verse: "Neither is there salvation in any other: for there is none other name under heaven given among men, whereby we must be saved."},
  {id: 85, ref: "Act4:13", verse: "Now when they saw the boldness of Peter and John, and perceived that they were unlearned and ignorant men, they marvelled; and they took knowledge of them, that they had been with Jesus."},
  {id: 86, ref: "Act4:14", verse: "And beholding the man which was healed standing with them, they could say nothing against it."},
  {id: 87, ref: "Act4:15", verse: "But when they had commanded them to go aside out of the council, they conferred among themselves,"},
  {id: 88, ref: "Act4:16", verse: "Saying, What shall we do to these men? for that indeed a notable miracle hath been done by them is manifest to all them that dwell in Jerusalem; and we cannot deny it."},
  {id: 89, ref: "Act4:17", verse: "But that it spread no further among the people, let us straitly threaten them, that they speak henceforth to no man in this name."},
  {id: 90, ref: "Act4:18", verse: "And they called them, and commanded them not to speak at all nor teach in the name of Jesus."},
  {id: 91, ref: "Act4:19", verse: "But Peter and John answered and said unto them, Whether it be right in the sight of God to hearken unto you more than unto God, judge ye."},
  {id: 92, ref: "Act4:20", verse: "For we cannot but speak the things which we have seen and heard."},
  {id: 93, ref: "Act4:21", verse: "So when they had further threatened them, they let them go, finding nothing how they might punish them, because of the people: for all men glorified God for that which was done."},
  {id: 94, ref: "Act4:22", verse: "For the man was above forty years old, on whom this miracle of healing was shewed."},
  {id: 95, ref: "Act5:1", verse: "But a certain man named Ananias, with Sapphira his wife, sold a possession,"},
  {id: 96, ref: "Act5:2", verse: "And kept back part of the price, his wife also being privy to it, and brought a certain part, and laid it at the apostles' feet."},
  {id: 97, ref: "Act5:3", verse: "But Peter said, Ananias, why hath Satan filled thine heart to lie to the Holy Ghost, and to keep back part of the price of the land?"},
  {id: 98, ref: "Act5:4", verse: "Whiles it remained, was it not thine own? and after it was sold, was it not in thine own power? why hast thou conceived this thing in thine heart? thou hast not lied unto men, but unto God."},
  {id: 99, ref: "Act5:5", verse: "And Ananias hearing these words fell down, and gave up the ghost: and great fear came on all them that heard these things."},
  {id: 100, ref: "Act5:6", verse: "And the young men arose, wound him up, and carried him out, and buried him."},
  {id: 101, ref: "Act5:7", verse: "And it was about the space of three hours after, when his wife, not knowing what was done, came in."},
  {id: 102, ref: "Act5:8", verse: "And Peter answered unto her, Tell me whether ye sold the land for so much? And she said, Yea, for so much."},
  {id: 103, ref: "Act5:9", verse: "Then Peter said unto her, How is it that ye have agreed together to tempt the Spirit of the Lord? behold, the feet of them which have buried thy husband are at the door, and shall carry thee out."},
  {id: 104, ref: "Act5:10", verse: "Then fell she down straightway at his feet, and yielded up the ghost: and the young men came in, and found her dead, and, carrying her forth, buried her by her husband."},
  {id: 105, ref: "Act5:11", verse: "And great fear came upon all the church, and upon as many as heard these things."},
  {id: 106, ref: "Act5:12", verse: "And by the hands of the apostles were many signs and wonders wrought among the people; (and they were all with one accord in Solomon's porch."},
  {id: 107, ref: "Act5:13", verse: "And of the rest durst no man join himself to them: but the people magnified them."},
  {id: 108, ref: "Act5:14", verse: "And believers were the more added to the Lord, multitudes both of men and women.)"},
  {id: 109, ref: "Act5:15", verse: "Insomuch that they brought forth the sick into the streets, and laid them on beds and couches, that at the least the shadow of Peter passing by might overshadow some of them."},
  {id: 110, ref: "Act5:16", verse: "There came also a multitude out of the cities round about unto Jerusalem, bringing sick folks, and them which were vexed with unclean spirits: and they were healed every one."},
  {id: 111, ref: "Act5:17", verse: "Then the high priest rose up, and all they that were with him, (which is the sect of the Sadducees,) and were filled with indignation,"},
  {id: 112, ref: "Act5:18", verse: "And laid their hands on the apostles, and put them in the common prison."},
  {id: 113, ref: "Act5:19", verse: "But the angel of the Lord by night opened the prison doors, and brought them forth, and said,"},
  {id: 114, ref: "Act5:20", verse: "Go, stand and speak in the temple to the people all the words of this life."},
  {id: 115, ref: "Act5:21", verse: "And when they heard that, they entered into the temple early in the morning, and taught. But the high priest came, and they that were with him, and called the council together, and all the senate of the children of Israel, and sent to the prison to have them brought."},
  {id: 116, ref: "Act5:22", verse: "But when the officers came, and found them not in the prison, they returned, and told,"},
  {id: 117, ref: "Act5:23", verse: "Saying, The prison truly found we shut with all safety, and the keepers standing without before the doors: but when we had opened, we found no man within."},
  {id: 118, ref: "Act5:24", verse: "Now when the high priest and the captain of the temple and the chief priests heard these things, they doubted of them whereunto this would grow."},
  {id: 119, ref: "Act5:25", verse: "Then came one and told them, saying, Behold, the men whom ye put in prison are standing in the temple, and teaching the people."},
  {id: 120, ref: "Act5:26", verse: "Then went the captain with the officers, and brought them without violence: for they feared the people, lest they should have been stoned."},
  {id: 121, ref: "Act5:27", verse: "And when they had brought them, they set them before the council: and the high priest asked them,"},
  {id: 122, ref: "Act5:28", verse: "Saying, Did not we straitly command you that ye should not teach in this name? and, behold, ye have filled Jerusalem with your doctrine, and intend to bring this man's blood upon us."},
  {id: 123, ref: "Act5:29", verse: "Then Peter and the other apostles answered and said, We ought to obey God rather than men."},
  {id: 124, ref: "Act6:1", verse: "And in those days, when the number of the disciples was multiplied, there arose a murmuring of the Grecians against the Hebrews, because their widows were neglected in the daily ministration."},
  {id: 125, ref: "Act6:2", verse: "Then the twelve called the multitude of the disciples unto them, and said, It is not reason that we should leave the word of God, and serve tables."},
  {id: 126, ref: "Act6:3", verse: "Wherefore, brethren, look ye out among you seven men of honest report, full of the Holy Ghost and wisdom, whom we may appoint over this business."},
  {id: 127, ref: "Act6:4", verse: "But we will give ourselves continually to prayer, and to the ministry of the word."},
  {id: 128, ref: "Act6:5", verse: "And the saying pleased the whole multitude: and they chose Stephen, a man full of faith and of the Holy Ghost, and Philip, and Prochorus, and Nicanor, and Timon, and Parmenas, and Nicolas a proselyte of Antioch:"},
  {id: 129, ref: "Act6:6", verse: "Whom they set before the apostles: and when they had prayed, they laid their hands on them."},
  {id: 130, ref: "Act6:7", verse: "And the word of God increased; and the number of the disciples multiplied in Jerusalem greatly; and a great company of the priests were obedient to the faith."},
  {id: 131, ref: "Act6:8", verse: "And Stephen, full of faith and power, did great wonders and miracles among the people."},
  {id: 132, ref: "Act8:1", verse: "And Saul was consenting unto his death. And at that time there was a great persecution against the church which was at Jerusalem; and they were all scattered abroad throughout the regions of Judaea and Samaria, except the apostles."},
  {id: 133, ref: "Act8:2", verse: "And devout men carried Stephen to his burial, and made great lamentation over him."},
  {id: 134, ref: "Act8:3", verse: "As for Saul, he made havock of the church, entering into every house, and haling men and women committed them to prison."},
  {id: 135, ref: "Act8:4", verse: "Therefore they that were scattered abroad went every where preaching the word."},
  {id: 136, ref: "Act8:5", verse: "Then Philip went down to the city of Samaria, and preached Christ unto them."},
  {id: 137, ref: "Act8:6", verse: "And the people with one accord gave heed unto those things which Philip spake, hearing and seeing the miracles which he did."},
  {id: 138, ref: "Act8:7", verse: "For unclean spirits, crying with loud voice, came out of many that were possessed with them: and many taken with palsies, and that were lame, were healed."},
  {id: 139, ref: "Act8:8", verse: "And there was great joy in that city."},
  {id: 140, ref: "Act8:9", verse: "But there was a certain man, called Simon, which beforetime in the same city used sorcery, and bewitched the people of Samaria, giving out that himself was some great one:"},
  {id: 141, ref: "Act8:10", verse: "To whom they all gave heed, from the least to the greatest, saying, This man is the great power of God."},
  {id: 142, ref: "Act8:11", verse: "And to him they had regard, because that of long time he had bewitched them with sorceries."},
  {id: 143, ref: "Act8:12", verse: "But when they believed Philip preaching the things concerning the kingdom of God, and the name of Jesus Christ, they were baptized, both men and women."},
  {id: 144, ref: "Act8:13", verse: "Then Simon himself believed also: and when he was baptized, he continued with Philip, and wondered, beholding the miracles and signs which were done."},
  {id: 145, ref: "Act8:14", verse: "Now when the apostles which were at Jerusalem heard that Samaria had received the word of God, they sent unto them Peter and John:"},
  {id: 146, ref: "Act8:15", verse: "Who, when they were come down, prayed for them, that they might receive the Holy Ghost:"},
  {id: 147, ref: "Act8:16", verse: "(For as yet he was fallen upon none of them: only they were baptized in the name of the Lord Jesus.)"},
  {id: 148, ref: "Act8:17", verse: "Then laid they their hands on them, and they received the Holy Ghost."},
  {id: 149, ref: "Act8:18", verse: "And when Simon saw that through laying on of the apostles' hands the Holy Ghost was given, he offered them money,"},
  {id: 150, ref: "Act8:19", verse: "Saying, Give me also this power, that on whomsoever I lay hands, he may receive the Holy Ghost."},
  {id: 151, ref: "Act8:20", verse: "But Peter said unto him, Thy money perish with thee, because thou hast thought that the gift of God may be purchased with money."},
  {id: 152, ref: "Act8:21", verse: "Thou hast neither part nor lot in this matter: for thy heart is not right in the sight of God."},
  {id: 153, ref: "Act8:22", verse: "Repent therefore of this thy wickedness, and pray God, if perhaps the thought of thine heart may be forgiven thee."},
  {id: 154, ref: "Act8:23", verse: "For I perceive that thou art in the gall of bitterness, and in the bond of iniquity."},
  {id: 155, ref: "Act8:24", verse: "Then answered Simon, and said, Pray ye to the Lord for me, that none of these things which ye have spoken come upon me."},
  {id: 156, ref: "Act8:25", verse: "And they, when they had testified and preached the word of the Lord, returned to Jerusalem, and preached the gospel in many villages of the Samaritans."},
  {id: 157, ref: "Act8:26", verse: "And the angel of the Lord spake unto Philip, saying, Arise, and go toward the south unto the way that goeth down from Jerusalem unto Gaza, which is desert."},
  {id: 158, ref: "Act8:27", verse: "And he arose and went: and, behold, a man of Ethiopia, an eunuch of great authority under Candace queen of the Ethiopians, who had the charge of all her treasure, and had come to Jerusalem for to worship,"},
  {id: 159, ref: "Act8:28", verse: "Was returning, and sitting in his chariot read Esaias the prophet."},
  {id: 160, ref: "Act8:29", verse: "Then the Spirit said unto Philip, Go near, and join thyself to this chariot."},
  {id: 161, ref: "Act8:30", verse: "And Philip ran thither to him, and heard him read the prophet Esaias, and said, Understandest thou what thou readest?"},
  {id: 162, ref: "Act8:31", verse: "And he said, How can I, except some man should guide me? And he desired Philip that he would come up and sit with him."},
  {id: 163, ref: "Act8:32", verse: "The place of the scripture which he read was this, He was led as a sheep to the slaughter; and like a lamb dumb before his shearer, so opened he not his mouth:"},
  {id: 164, ref: "Act8:33", verse: "In his humiliation his judgment was taken away: and who shall declare his generation? for his life is taken from the earth."},
  {id: 165, ref: "Act8:34", verse: "And the eunuch answered Philip, and said, I pray thee, of whom speaketh the prophet this? of himself, or of some other man?"},
  {id: 166, ref: "Act8:35", verse: "Then Philip opened his mouth, and began at the same scripture, and preached unto him Jesus."},
  {id: 167, ref: "Act8:36", verse: "And as they went on their way, they came unto a certain water: and the eunuch said, See, here is water; what doth hinder me to be baptized?"},
  {id: 168, ref: "Act8:37", verse: "And Philip said, If thou believest with all thine heart, thou mayest. And he answered and said, I believe that Jesus Christ is the Son of God."},
  {id: 169, ref: "Act8:38", verse: "And he commanded the chariot to stand still: and they went down both into the water, both Philip and the eunuch; and he baptized him."},
  {id: 170, ref: "Act8:39", verse: "And when they were come up out of the water, the Spirit of the Lord caught away Philip, that the eunuch saw him no more: and he went on his way rejoicing."},
  {id: 171, ref: "Act9:1", verse: "And Saul, yet breathing out threatenings and slaughter against the disciples of the Lord, went unto the high priest,"},
  {id: 172, ref: "Act9:2", verse: "And desired of him letters to Damascus to the synagogues, that if he found any of this way, whether they were men or women, he might bring them bound unto Jerusalem."},
  {id: 173, ref: "Act9:3", verse: "And as he journeyed, he came near Damascus: and suddenly there shined round about him a light from heaven:"},
  {id: 174, ref: "Act9:4", verse: "And he fell to the earth, and heard a voice saying unto him, Saul, Saul, why persecutest thou me?"},
  {id: 175, ref: "Act9:5", verse: "And he said, Who art thou, Lord? And the Lord said, I am Jesus whom thou persecutest: it is hard for thee to kick against the pricks."},
  {id: 176, ref: "Act9:6", verse: "And he trembling and astonished said, Lord, what wilt thou have me to do? And the Lord said unto him, Arise, and go into the city, and it shall be told thee what thou must do."},
  {id: 177, ref: "Act9:7", verse: "And the men which journeyed with him stood speechless, hearing a voice, but seeing no man."},
  {id: 178, ref: "Act9:8", verse: "And Saul arose from the earth; and when his eyes were opened, he saw no man: but they led him by the hand, and brought him into Damascus."},
  {id: 179, ref: "Act9:9", verse: "And he was three days without sight, and neither did eat nor drink."},
  {id: 180, ref: "Act9:10", verse: "And there was a certain disciple at Damascus, named Ananias; and to him said the Lord in a vision, Ananias. And he said, Behold, I am here, Lord."},
  {id: 181, ref: "Act9:11", verse: "And the Lord said unto him, Arise, and go into the street which is called Straight, and enquire in the house of Judas for one called Saul, of Tarsus: for, behold, he prayeth,"},
  {id: 182, ref: "Act9:12", verse: "And hath seen in a vision a man named Ananias coming in, and putting his hand on him, that he might receive his sight."},
  {id: 183, ref: "Act9:13", verse: "Then Ananias answered, Lord, I have heard by many of this man, how much evil he hath done to thy saints at Jerusalem:"},
  {id: 184, ref: "Act9:14", verse: "And here he hath authority from the chief priests to bind all that call on thy name."},
  {id: 185, ref: "Act9:15", verse: "But the Lord said unto him, Go thy way: for he is a chosen vessel unto me, to bear my name before the Gentiles, and kings, and the children of Israel:"},
  {id: 186, ref: "Act9:16", verse: "For I will shew him how great things he must suffer for my name's sake."},
  {id: 187, ref: "Act9:17", verse: "And Ananias went his way, and entered into the house; and putting his hands on him said, Brother Saul, the Lord, even Jesus, that appeared unto thee in the way as thou camest, hath sent me, that thou mightest receive thy sight, and be filled with the Holy Ghost."},
  {id: 188, ref: "Act9:18", verse: "And immediately there fell from his eyes as it had been scales: and he received sight forthwith, and arose, and was baptized."},
  {id: 189, ref: "Act9:19", verse: "And when he had received meat, he was strengthened. Then was Saul certain days with the disciples which were at Damascus."},
  {id: 190, ref: "Act9:20", verse: "And straightway he preached Christ in the synagogues, that he is the Son of God."},
  {id: 191, ref: "Act9:21", verse: "But all that heard him were amazed, and said; Is not this he that destroyed them which called on this name in Jerusalem, and came hither for that intent, that he might bring them bound unto the chief priests?"},
  {id: 192, ref: "Act9:22", verse: "But Saul increased the more in strength, and confounded the Jews which dwelt at Damascus, proving that this is very Christ."},
  {id: 193, ref: "Act9:23", verse: "And after that many days were fulfilled, the Jews took counsel to kill him:"},
  {id: 194, ref: "Act9:24", verse: "But their laying await was known of Saul. And they watched the gates day and night to kill him."},
  {id: 195, ref: "Act9:25", verse: "Then the disciples took him by night, and let him down by the wall in a basket."},
  {id: 196, ref: "Act9:26", verse: "And when Saul was come to Jerusalem, he assayed to join himself to the disciples: but they were all afraid of him, and believed not that he was a disciple."},
  {id: 197, ref: "Act9:27", verse: "But Barnabas took him, and brought him to the apostles, and declared unto them how he had seen the Lord in the way, and that he had spoken to him, and how he had preached boldly at Damascus in the name of Jesus."},
  {id: 198, ref: "Act9:28", verse: "And he was with them coming in and going out at Jerusalem."},
  {id: 199, ref: "Act9:29", verse: "And he spake boldly in the name of the Lord Jesus, and disputed against the Grecians: but they went about to slay him."},
  {id: 200, ref: "Act9:30", verse: "Which when the brethren knew, they brought him down to Caesarea, and sent him forth to Tarsus."},
  {id: 201, ref: "Act9:31", verse: "Then had the churches rest throughout all Judaea and Galilee and Samaria, and were edified; and walking in the fear of the Lord, and in the comfort of the Holy Ghost, were multiplied."},
  {id: 202, ref: "Act9:32", verse: "And it came to pass, as Peter passed throughout all quarters, he came down also to the saints which dwelt at Lydda."},
  {id: 203, ref: "Act9:33", verse: "And there he found a certain man named Aeneas, which had kept his bed eight years, and was sick of the palsy."},
  {id: 204, ref: "Act9:34", verse: "And Peter said unto him, Aeneas, Jesus Christ maketh thee whole: arise, and make thy bed. And he arose immediately."},
  {id: 205, ref: "Act9:35", verse: "And all that dwelt at Lydda and Saron saw him, and turned to the Lord."},
  {id: 206, ref: "Act9:36", verse: "Now there was at Joppa a certain disciple named Tabitha, which by interpretation is called Dorcas: this woman was full of good works and almsdeeds which she did."},
  {id: 207, ref: "Act9:37", verse: "And it came to pass in those days, that she was sick, and died: whom when they had washed, they laid her in an upper chamber."},
  {id: 208, ref: "Act9:38", verse: "And forasmuch as Lydda was nigh to Joppa, and the disciples had heard that Peter was there, they sent unto him two men, desiring him that he would not delay to come to them."},
  {id: 209, ref: "Act9:39", verse: "Then Peter arose and went with them. When he was come, they brought him into the upper chamber: and all the widows stood by him weeping, and shewing the coats and garments which Dorcas made, while she was with them."},
  {id: 210, ref: "Act9:40", verse: "But Peter put them all forth, and kneeled down, and prayed; and turning him to the body said, Tabitha, arise. And she opened her eyes: and when she saw Peter, she sat up."},
  {id: 211, ref: "Act9:41", verse: "And he gave her his hand, and lifted her up, and when he had called the saints and widows, presented her alive."},
  {id: 212, ref: "Act9:42", verse: "And it was known throughout all Joppa; and many believed in the Lord."},
  {id: 213, ref: "Act9:43", verse: "And it came to pass, that he tarried many days in Joppa with one Simon a tanner."},
  {id: 214, ref: "Act10:1", verse: "There was a certain man in Caesarea called Cornelius, a centurion of the band called the Italian band,"},
  {id: 215, ref: "Act10:2", verse: "A devout man, and one that feared God with all his house, which gave much alms to the people, and prayed to God alway."},
  {id: 216, ref: "Act10:3", verse: "He saw in a vision evidently about the ninth hour of the day an angel of God coming in to him, and saying unto him, Cornelius."},
  {id: 217, ref: "Act10:4", verse: "And when he looked on him, he was afraid, and said, What is it, Lord? And he said unto him, Thy prayers and thine alms are come up for a memorial before God."},
  {id: 218, ref: "Act10:5", verse: "And now send men to Joppa, and call for one Simon, whose surname is Peter:"},
  {id: 219, ref: "Act10:6", verse: "He lodgeth with one Simon a tanner, whose house is by the sea side: he shall tell thee what thou oughtest to do."},
  {id: 220, ref: "Act10:7", verse: "And when the angel which spake unto Cornelius was departed, he called two of his household servants, and a devout soldier of them that waited on him continually;"},
  {id: 221, ref: "Act10:8", verse: "And when he had declared all these things unto them, he sent them to Joppa."},
  {id: 222, ref: "Act10:9", verse: "On the morrow, as they went on their journey, and drew nigh unto the city, Peter went up upon the housetop to pray about the sixth hour:"},
  {id: 223, ref: "Act10:10", verse: "And he became very hungry, and would have eaten: but while they made ready, he fell into a trance,"},
  {id: 224, ref: "Act10:11", verse: "And saw heaven opened, and a certain vessel descending unto him, as it had been a great sheet knit at the four corners, and let down to the earth:"},
  {id: 225, ref: "Act10:12", verse: "Wherein were all manner of fourfooted beasts of the earth, and wild beasts, and creeping things, and fowls of the air."},
  {id: 226, ref: "Act10:13", verse: "And there came a voice to him, Rise, Peter; kill, and eat."},
  {id: 227, ref: "Act10:14", verse: "But Peter said, Not so, Lord; for I have never eaten any thing that is common or unclean."},
  {id: 228, ref: "Act10:15", verse: "And the voice spake unto him again the second time, What God hath cleansed, that call not thou common."},
  {id: 229, ref: "Act10:16", verse: "This was done thrice: and the vessel was received up again into heaven."},
  {id: 230, ref: "Act10:17", verse: "Now while Peter doubted in himself what this vision which he had seen should mean, behold, the men which were sent from Cornelius had made enquiry for Simon's house, and stood before the gate,"},
  {id: 231, ref: "Act10:18", verse: "And called, and asked whether Simon, which was surnamed Peter, were lodged there."},
  {id: 232, ref: "Act10:19", verse: "While Peter thought on the vision, the Spirit said unto him, Behold, three men seek thee."},
  {id: 233, ref: "Act10:20", verse: "Arise therefore, and get thee down, and go with them, doubting nothing: for I have sent them."},
  {id: 234, ref: "Act10:34", verse: "Then Peter opened his mouth, and said, Of a truth I perceive that God is no respecter of persons:"},
  {id: 235, ref: "Act10:35", verse: "But in every nation he that feareth him, and worketh righteousness, is accepted with him."},
  {id: 236, ref: "Act10:36", verse: "The word which God sent unto the children of Israel, preaching peace by Jesus Christ: (he is Lord of all:)"},
  {id: 237, ref: "Act10:37", verse: "That word, I say, ye know, which was published throughout all Judaea, and began from Galilee, after the baptism which John preached;"},
  {id: 238, ref: "Act10:38", verse: "How God anointed Jesus of Nazareth with the Holy Ghost and with power: who went about doing good, and healing all that were oppressed of the devil; for God was with him."},
  {id: 239, ref: "Act10:39", verse: "And we are witnesses of all things which he did both in the land of the Jews, and in Jerusalem; whom they slew and hanged on a tree:"},
  {id: 240, ref: "Act10:40", verse: "Him God raised up the third day, and shewed him openly;"},
  {id: 241, ref: "Act10:41", verse: "Not to all the people, but unto witnesses chosen before of God, even to us, who did eat and drink with him after he rose from the dead."},
  {id: 242, ref: "Act10:42", verse: "And he commanded us to preach unto the people, and to testify that it is he which was ordained of God to be the Judge of quick and dead."},
  {id: 243, ref: "Act10:43", verse: "To him give all the prophets witness, that through his name whosoever believeth in him shall receive remission of sins."},
  {id: 244, ref: "Act10:44", verse: "While Peter yet spake these words, the Holy Ghost fell on all them which heard the word."},
  {id: 245, ref: "Act10:45", verse: "And they of the circumcision which believed were astonished, as many as came with Peter, because that on the Gentiles also was poured out the gift of the Holy Ghost."},
  {id: 246, ref: "Act10:46", verse: "For they heard them speak with tongues, and magnify God. Then answered Peter,"},
  {id: 247, ref: "Act10:47", verse: "Can any man forbid water, that these should not be baptized, which have received the Holy Ghost as well as we?"},
  {id: 248, ref: "Act10:48", verse: "And he commanded them to be baptized in the name of the Lord. Then prayed they him to tarry certain days."},
  {id: 249, ref: "Act12:1", verse: "Now about that time Herod the king stretched forth his hands to vex certain of the church."},
  {id: 250, ref: "Act12:2", verse: "And he killed James the brother of John with the sword."},
  {id: 251, ref: "Act12:3", verse: "And because he saw it pleased the Jews, he proceeded further to take Peter also. (Then were the days of unleavened bread.)"},
  {id: 252, ref: "Act12:4", verse: "And when he had apprehended him, he put him in prison, and delivered him to four quaternions of soldiers to keep him; intending after Easter to bring him forth to the people."},
  {id: 253, ref: "Act12:5", verse: "Peter therefore was kept in prison: but prayer was made without ceasing of the church unto God for him."},
  {id: 254, ref: "Act12:6", verse: "And when Herod would have brought him forth, the same night Peter was sleeping between two soldiers, bound with two chains: and the keepers before the door kept the prison."},
  {id: 255, ref: "Act12:7", verse: "And, behold, the angel of the Lord came upon him, and a light shined in the prison: and he smote Peter on the side, and raised him up, saying, Arise up quickly. And his chains fell off from his hands."},
  {id: 256, ref: "Act12:8", verse: "And the angel said unto him, Gird thyself, and bind on thy sandals. And so he did. And he saith unto him, Cast thy garment about thee, and follow me."},
  {id: 257, ref: "Act12:9", verse: "And he went out, and followed him; and wist not that it was true which was done by the angel; but thought he saw a vision."},
  {id: 258, ref: "Act12:10", verse: "When they were past the first and the second ward, they came unto the iron gate that leadeth unto the city; which opened to them of his own accord: and they went out, and passed on through one street; and forthwith the angel departed from him."},
  {id: 259, ref: "Act12:11", verse: "And when Peter was come to himself, he said, Now I know of a surety, that the Lord hath sent his angel, and hath delivered me out of the hand of Herod, and from all the expectation of the people of the Jews."},
  {id: 260, ref: "Act12:12", verse: "And when he had considered the thing, he came to the house of Mary the mother of John, whose surname was Mark; where many were gathered together praying."},
  {id: 261, ref: "Act12:13", verse: "And as Peter knocked at the door of the gate, a damsel came to hearken, named Rhoda."},
  {id: 262, ref: "Act12:14", verse: "And when she knew Peter's voice, she opened not the gate for gladness, but ran in, and told how Peter stood before the gate."},
  {id: 263, ref: "Act12:15", verse: "And they said unto her, Thou art mad. But she constantly affirmed that it was even so. Then said they, It is his angel."},
  {id: 264, ref: "Act12:16", verse: "But Peter continued knocking: and when they had opened the door, and saw him, they were astonished."},
  {id: 265, ref: "Act12:17", verse: "But he, beckoning unto them with the hand to hold their peace, declared unto them how the Lord had brought him out of the prison. And he said, Go shew these things unto James, and to the brethren. And he departed, and went into another place."}
];

export const getJrverses = () => jrverses;
