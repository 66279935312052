import { IonContent, IonHeader, IonItem, IonLabel, IonList, IonPage, IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useState } from 'react';
//import ExploreContainer from '../components/ExploreContainer';
import { get, set } from '../data/IonicStorage';
import './Tab2.css';
//import useFetch from "../components/useFetch";
//import axios from 'axios';
import React from 'react';
//import { getVersionInfo } from "../services/upcquizAPI";

const Tab2: React.FC = () => {
  //const [ listItems, setListItems ] = useState<any>([]);

  const [division, setDivision] = useState<string>("jr");
  //const [endvalue, setEndvalue] = useState(265);  //265
  //const { data, refetch } = useFetch("https://www.upcquiz.com/apps/bqlookup/version.json");
  //console.log(data);
 /* const sendRequest = () => {
    return axios
        .get('https://www.upcquiz.com/apps/bqlookup/version.json',{
         headers:{
                  'app-id':'<BQLookup22>',
                  'Content-Type' : 'application/json'
    },
    })
    .then((response) => {
    console.log(response);
    return response.data;
    })
    };
    React.useEffect(() => {
      sendRequest().then(data => {
              setListItems(data.data)
      });
      }, []);*/
  useEffect(() => {
    const setupDivision = async () => {
      const division = await get("division");
      setDivision(division);
    }
    setupDivision();
  }, []);

  /*useEffect(() => {
    getVersionInfo().then(res => setVersion(res.data));
  });*/

	/*useIonViewWillEnter(async () => {		
		const division = await get("division");
    setDivision(division);
	});*/
  
    function updateDivision(value: any): void {
      set("division", value);
      if (value === "beg") {
        updateEndverse(170);  //170
      } else if (value === "jr") {
        updateEndverse(265);  //265
      } else if (value === "int") {
        updateEndverse(414);  //414
      } else {
        updateEndverse(519);   //519
      }
    }

    function updateEndverse(value: any): void {
      set("endverse", value);   
    }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Settings</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList>
          <IonItem>
            <IonLabel>Division</IonLabel>
            <IonSelect value={division} onIonChange={e => updateDivision(e.detail.value)}>
              <IonSelectOption value="beg">Beginner</IonSelectOption>
              <IonSelectOption value="jr">Junior</IonSelectOption>
              <IonSelectOption value="int">Intermediate</IonSelectOption>
              <IonSelectOption value="exp">Experienced</IonSelectOption>
            </IonSelect>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Tab2;

