import { createTheme, List, makeStyles, useMediaQuery } from "@material-ui/core";
  import React from "react";
import { VerseListItem } from "./VerseListItem";


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxHeight: '100%',
    overflow: 'auto',
    position: 'relative',
    /*backgroundColor: theme.palette.background.paper,*/
  },
}));

  export const VerseList: React.FC<any> = ({ verses }) => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = React.useMemo(
      () =>
        createTheme({
          palette: {
            type: prefersDarkMode ? 'dark' : 'light',
          },
        }),
      [prefersDarkMode],
    );
    
        const classes = useStyles();

    return (
    <div className={classes.root}>
      <List style={{maxHeight: '100%', overflow: 'auto'}} >
        {verses?.map((verse: any) => {
          return (
            <VerseListItem key={verse.id} props={verse}></VerseListItem>
          );
        })}
      </List>
      </div>
    );
  };
  