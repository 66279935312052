import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { Box } from '@material-ui/core';
import ExploreContainer from '../components/ExploreContainer';
import './Tab3.css';

const Tab3: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Help Page</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
      <Box marginLeft={2} marginRight={2} style={{maxHeight: '100%', position: 'relative'}}>
      <p>To look up a verse, type chapter.verse.<br/>
Ex:  psa6.6 or psa8.7<br/><br/>
To look up a key word or phrase, type the key word or phrase.<br/><br/>
To look up all verses that contain two or more given words, separate each of the words with a space.<br/>
Ex: mercy truth would return all verses containing both mercy AND truth<br/><br/>
To look up two verses and/or phrases, type the first then '/' then the second (no spaces between them)
(use this for looking up cross references).<br/>Ex: gal5.15/wind   gal1.13/heb2.8/heb4.12<br/><br/>
So mercy truth would return all verses containing both mercy AND truth 
whereas mercy/truth would return all verses containing either mercy OR truth 
(not necessarily both in the same verse).<br/><br/>
For Quotation Completion questions, start with a $ in the lookup field.<br/>
Ex:  $For thou hast  would return all verses starting with that exact phrase<br/><br/>
For Verse Ending questions, include a - in the lookup field.<br/>
Ex:  -mercy  would return all verses ending with that exact word or phrase<br/><br/>
To look up an exact phrase, start the phrase with a single quote.<br/>
Ex:  'Lord of hosts  would return all verses with that exact phrase<br/><br/>
NOT YET IMPLEMENTED --
End a material lookup with one or more plus signs (+), such that each + will cushion 2 verses around the 
returned material. For example, gal2.12+ will return verses gal2.10 through gal2.14. This is useful for questions 
relating to previous and following verses.</p>
      </Box>
      </IonContent>
    </IonPage>
  );
};

export default Tab3;
