import { IonContent, IonHeader, IonPage, IonSearchbar, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import './Tab1.css';
import { Box } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useEffect, useReducer, useState } from 'react';
import { VerseList } from '../components/VerseList';
// Getting local JSON file
//import JrVerses from "../Jrverses.json";
//import { get, set } from '../data/IonicStorage';
//import { getJrverses } from '../data/jrverses';
import { createStore, get, set } from '../data/IonicStorage';
import { getJrverses } from '../data/jrverses';
import { getSrverses } from '../data/srverses';
import React from 'react';

// Initial State And Reducer Function
 const initialState = {
  isLoading: true,
  data: [],
  search: '',
  searchData: [],
}

const reducer = (state: any, action: { type: any; payload: any; }) => {
  switch (action.type) {
    case 'SET_DATA':
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      }
    case 'SEARCH_INPUT':
      return { ...state, search: action.payload }
    case 'SEARCH_DATA':
      return { ...state, searchData: action.payload }
    default:
      throw new Error()
  }
}

const Tab1: React.FC = () => {

  const [state, dispatch] = useReducer(reducer, initialState);
	const [jrverses, setJrverses] = useState<{ id: number; ref: string; verse: string; }[]>([]);
	const [srverses, setSrverses] = useState<{ id: number; ref: string; verse: string; }[]>([]);
	const [currentdiv, setCurrentdiv] = useState<string>("jr");
	const [endverse, setEndverse] = useState<number>(265);
 
  useEffect(() => {

    const setupStore = async () => {
      await createStore("LookupDB24b");

      // get jr verses
      //const exists = await get("jrverses");
      //if (!exists) {      
        const jrverses1: {
          id: number;
          ref: string;
          verse: string;
      }[] = getJrverses();
        set("jrverses", jrverses1);
        setJrverses(jrverses1);
        //dispatch({ type: 'SET_DATA', payload: jrverses })
      /*} else {
        setJrverses(exists);
        //dispatch({ type: 'SET_DATA', payload: exists })
      }*/

      // get sr verses
      //const srexists = await get("srverses");
      //if (!srexists) {         
          const srverses1: {
            id: number;
            ref: string;
            verse: string;
        }[] = getSrverses();
          set("srverses", srverses1);
          setSrverses(srverses1);
          //dispatch({ type: 'SET_DATA', payload: srverses })
      /*} else {
          setSrverses(srexists);
          //dispatch({ type: 'SET_DATA', payload: srexists })
      }*/
    
    }

    setupStore();
  
  }, []);

  useIonViewWillEnter(async () => {		
    const div_exists = await get("division");
    console.log("in useIonViewWillEnter div_exists: " + div_exists);

    if (!div_exists) {
      console.log("division did not exist in DB!");
      //setCurrentdiv("jr");
      set("division", currentdiv);
      //setEndverse(265); //265
      set("endverse", 265);  //265
    } else {
      setCurrentdiv(div_exists);
      const ev_exists = await get("endverse");
      if (!ev_exists) { // set endverse to be last verse for selected division
        console.log("endverse did not exist in DB!");
        if (div_exists === "beg") {
          setEndverse(170); //170
          set("endverse", 170); //170
        } else if (div_exists === "jr") {
          setEndverse(265); //265
          set("endverse", 265); //265
        } else if (div_exists === "int") {
          setEndverse(414); //414
          set("endverse", 414);  //414
        } else {
          setEndverse(519);  //519
          set("endverse", 519);  //519
        }
      } else {
        setEndverse(ev_exists);
        set("endverse", ev_exists);
      }

      //const endverse = await get("endverse");
      //setEndverse(endverse);
    }
	});

  useEffect(() => {
    console.log("current division changed to " + currentdiv); 
    if (currentdiv != null) {
    if (currentdiv === "beg") {
      if (jrverses.length <= 0) {
        const jrverses = getJrverses();
        let versearr = jrverses.slice(0,170); //170
        console.log("setting beg data array of length " + versearr.length); 
        dispatch({ type: 'SET_DATA', payload: versearr })
      } else {
        let versearr = jrverses.slice(0,170); //170
        console.log("setting beg data array of length " + versearr.length); 
        dispatch({ type: 'SET_DATA', payload: versearr })
      }
    } else if (currentdiv === "jr") {
      if (jrverses.length <= 0) {
        const jrverses = getJrverses();
        console.log("setting jr data array of length " + jrverses.length); 
        dispatch({ type: 'SET_DATA', payload: jrverses })
      } else { 
        console.log("setting jr data array of length " + jrverses.length); 
        dispatch({ type: 'SET_DATA', payload: jrverses })
     }
    } else if (currentdiv === "int") {
      if (srverses.length > 0) {
        let versearr = srverses.slice(0,414);  //414
        console.log("setting int data array of length " + versearr.length); 
        dispatch({ type: 'SET_DATA', payload: versearr })
      } else { setCurrentdiv(currentdiv)}
    } else {
      if (srverses.length > 0) {
        console.log("setting exp data array of length " + srverses.length); 
        dispatch({ type: 'SET_DATA', payload: srverses })
      } else { setCurrentdiv(currentdiv)}
    }}

  }, [currentdiv]); // Call this function when the value of `currentdiv` changes

  useEffect(() => {
    console.log("current endverse changed to " + endverse); 

  }, [endverse]); // Call this function when the value of `endverse` changes

/*  const testFullImportFromJson = async (): Promise<boolean>  => {
    //var versesjr = JrVerses.jrverses;
    dispatch({ type: 'SET_DATA', payload: jrverses })
    return true;
   };


  const testImportFromJson = useCallback(async (): Promise<boolean>  => {
    let ret: boolean = false;  
    ret = await testFullImportFromJson();
    if(!ret) {
        return false;
    }      
     return true;
}, []);


  useEffect(() => {
      testImportFromJson();

}, []);*/

function stripExtra(s:String):String
{
  var good:String = " abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789?!";
  var idx = s.indexOf(" ");
  var resul:String = s.substring(0,idx + 1);
  for (var i= idx+1; i < s.length; i++)
  {
    if (good.indexOf(s.charAt(i)) >= 0)
    {
      resul = resul + s.charAt(i);
    }
  }
  return resul.toLowerCase();
}

  // Search And Highlight Function
  const handleInput = (e: string) => {
    let str = e;

    dispatch({ type: 'SEARCH_INPUT', payload: str })
    //window.alert(JSON.stringify(str, null, 2));
    //window.alert(JSON.stringify(state.search, null, 2));
    //window.alert(JSON.stringify(state.data, null, 2));

    // how many verses to cushion search results by
    let cushion = (str.match(/\+/g) || []).length;
    let lower: string = str.substring(0,str.length - cushion);   // search term without cushion +'s
    cushion = cushion*2;   // how many verses to pad above and below (two verses for each +)
    lower = lower.toLowerCase();  // makes search term(s) lowercase


    if (lower.indexOf("$") != -1) {   // means are searching for quotation completion (starts with ...)
      lower = lower.replace("$","");
      const newArr = state.data
      .filter(
        (          item: { id: string, ref: string; verse: string; }) =>
          item.ref.replace(":",".").toLowerCase().includes(lower) ||
          stripExtra(item.verse).startsWith(lower)
      )
      .map((item: { id: string, ref: string; verse: string; }) => {
        let newRef = item.ref.replace(
          new RegExp(lower, 'gi'),
          (            match: any) =>
            `<mark style="background: #cf3c4f; color: #e0e0eb;">${match}</mark>`
        )
        let newVerse = item.verse.replace(
          new RegExp(lower, 'gi'),
          (            match: any) =>
          `<mark style="background: #cf3c4f; color: #e0e0eb;">${match}</mark>`
        )
        return {
          ...item,
          id: item.id,
          ref: newRef,
          verse: newVerse,
        }
      })
      dispatch({ type: 'SEARCH_DATA', payload: newArr })
    }   // end search for quotation completion (starts with ...)
    else if (lower.indexOf("-") != -1) {   // means are searching for ending (ends with ...)
      lower = lower.replace("-","");
      const newArr = state.data
      .filter(
        (          item: { id: string, ref: string; verse: string; }) =>
          item.ref.replace(":",".").toLowerCase().includes(lower) ||
          stripExtra(item.verse).endsWith(lower)
      )
      .map((item: { id: string, ref: string; verse: string; }) => {
        let newRef = item.ref.replace(
          new RegExp(lower, 'gi'),
          (            match: any) =>
            `<mark style="background: #cf3c4f; color: #e0e0eb;">${match}</mark>`
        )
        let newVerse = item.verse.replace(
          new RegExp(lower, 'gi'),
          (            match: any) =>
          `<mark style="background: #cf3c4f; color: #e0e0eb;">${match}</mark>`
        )
        return {
          ...item,
          id: item.id,
          ref: newRef,
          verse: newVerse,
        }
      })
      dispatch({ type: 'SEARCH_DATA', payload: newArr })
    }   // end search for ending (ends with ...)
    else if ((lower.indexOf("`") != -1) || (lower.indexOf("'") != -1)) {   // means are searching for a unique phrase in verse
      lower = lower.replace("`","");
      lower = lower.replace("'","");
      const newArr = state.data
      .filter(
        (          item: { id: string, ref: string; verse: string; }) =>
          item.ref.replace(":",".").toLowerCase().includes(lower) ||
          stripExtra(item.verse).includes(lower)
      )
      .map((item: { id: string, ref: string; verse: string; }) => {
        let newRef = item.ref.replace(
          new RegExp(lower, 'gi'),
          (            match: any) =>
            `<mark style="background: #cf3c4f; color: #e0e0eb;">${match}</mark>`
        )
        let newVerse = item.verse.replace(
          new RegExp(lower, 'gi'),
          (            match: any) =>
          `<mark style="background: #cf3c4f; color: #e0e0eb;">${match}</mark>`
        )
        return {
          ...item,
          id: item.id,
          ref: newRef,
          verse: newVerse,
        }
      })
      dispatch({ type: 'SEARCH_DATA', payload: newArr })
    }   // end search for unique phrase
    else if ((lower.indexOf(" ") != -1) && (lower.indexOf("/") == -1)) {   
      // means are searching for multiple things in same verse
      let tokenArr = lower.split(" ");
      // check verses for each search token, narrowing results each time
      let newArr = state.data;

      // loop to narrow to verses containing all of the search tokens
      for (var i=0; i < tokenArr.length; i++) {
        let tokenstr = tokenArr[i].trim();
        if (tokenstr.length>0) {
        //window.alert(JSON.stringify(tokenstr, null, 2));
        newArr = newArr
        .filter(
          (          item: { id: string, ref: string; verse: string; }) =>
            item.ref.replace(":",".").toLowerCase().includes(tokenstr) ||
            stripExtra(item.verse).includes(tokenstr)
        )
        .map((item: { id: string, ref: string; verse: string; }) => {
          return {
            ...item,
            id: item.id,
            ref: item.ref,
            verse: item.verse,
          }
        }) 
        }
      }

      // cycle through resulting array to highlight only the search tokens
      for (var i=0; i < tokenArr.length; i++) {
        let tokenstr = tokenArr[i].trim();
        if (tokenstr.length>0) {
          newArr = newArr
        .map((item: { id: string, ref: string; verse: string; }) => {
          if (!("<mark style=background: #cf3c4f; color: #e0e0eb;".includes(tokenstr))) {
          let newRef = item.ref.replace(
            new RegExp(tokenstr, 'gi'),
            (            match: any) =>
              `<mark style="background: #cf3c4f; color: #e0e0eb;">${match}</mark>`
          )
          let newVerse = item.verse.replace(
            new RegExp(tokenstr, 'gi'),
            (            match: any) =>
            `<mark style="background: #cf3c4f; color: #e0e0eb;">${match}</mark>`
          )
          return {
            ...item,
            id: item.id,
            ref: newRef,
            verse: newVerse,
          }} else {
            return {
              ...item,
              id: item.id,
              ref: item.ref,
              verse: item.verse,
          }}
        })
      }
      }

      dispatch({ type: 'SEARCH_DATA', payload: newArr })
    }   // end search for multiple things in same verse
    else {   // means are searching for any items in verse
      let tokenArr = lower.split("/");
      let newArr = [];
      for (var i=0; i<state.data.length; i++) {
        let found = false;
        for (var j=0; j<tokenArr.length; j++) {
          if (state.data[i].ref.replace(":",".").toLowerCase().includes(tokenArr[j]) ||
          stripExtra(state.data[i].verse).includes(tokenArr[j])) {
            found = true;
          }
        }
        if (found) {
          // cushion verse base on how many +
          for (var j=cushion; j>0; j--) {
            if (i - j >= 0){
              newArr.push(state.data[i-j]);
            }
          }
          newArr.push(state.data[i]);
          for (var j1=1; j1<=cushion; j1++) {
            if (i + j1 < state.data.length) {
              newArr.push(state.data[i+j1]);
            }
          }
        }
      }
      // cycle through resulting array to highlight only the search tokens
      for (var i=0; i < tokenArr.length; i++) {
        let tokenstr = tokenArr[i].trim();
        if (tokenstr.length>0) {
          newArr = newArr
        .map((item: { id: string, ref: string; verse: string; }) => {
          if (!("<mark style=background: #cf3c4f; color: #e0e0eb;".includes(tokenstr))) {
          let newRef = item.ref.replace(
            new RegExp(tokenstr, 'gi'),
            (            match: any) =>
              `<mark style="background: #cf3c4f; color: #e0e0eb;">${match}</mark>`
          )
          let newVerse = item.verse.replace(
            new RegExp(tokenstr, 'gi'),
            (            match: any) =>
            `<mark style="background: #cf3c4f; color: #e0e0eb;">${match}</mark>`
          )
          return {
            ...item,
            id: item.id,
            ref: newRef,
            verse: newVerse,
          }} else {
            return {
              ...item,
              id: item.id,
              ref: item.ref,
              verse: item.verse,
          }}
        })
      }
      }        dispatch({ type: 'SEARCH_DATA', payload: newArr })
    }   // end search for any items in verse
    //window.alert(JSON.stringify(count, null, 2));
    //window.alert(JSON.stringify(newArr, null, 2));

  }  

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
const theme = React.useMemo(
  () =>
    createTheme({
      palette: {
        type: prefersDarkMode ? 'dark' : 'light',
      },
    }),
  [prefersDarkMode],
);


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{currentdiv} Lookup thru {endverse} Verses</IonTitle>
        </IonToolbar>
        <IonToolbar>
        <IonSearchbar onIonChange={e => handleInput(e.detail.value!)} showCancelButton="never"></IonSearchbar>
      </IonToolbar>
       </IonHeader>
      <IonContent fullscreen>
      <Box style={{maxHeight: '100%', position: 'relative'}}>
      {state.isLoading ? (
          <p>Loading...</p>
        ) : state.search.length > 0 ? (<VerseList verses={state.searchData} />
        ) : (
          <VerseList verses={state.data} />
        )}
      </Box>
      </IonContent>
    </IonPage>
  );
};

export default Tab1;

