  import React from "react";
  import Typography from '@material-ui/core/Typography'
import { createTheme, ListItem, ListItemText, useMediaQuery } from "@material-ui/core";
     
  export const VerseListItem: React.FC<any> = ({ props }) => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = React.useMemo(
      () =>
        createTheme({
          palette: {
            type: prefersDarkMode ? 'dark' : 'light',
          },
        }),
      [prefersDarkMode],
    );

    const createMarkup = (html: any) => {
      return { __html: html }
    }
  
  
    return (
      <ListItem>
      <ListItemText>
        <Typography dangerouslySetInnerHTML={createMarkup(props.ref + " " + props.verse)} />
      </ListItemText>
    </ListItem>
    );
  };