const srverses = [
  {id: 1, ref: "Act1:1", verse: "The former treatise have I made, O Theophilus, of all that Jesus began both to do and teach,"},
  {id: 2, ref: "Act1:2", verse: "Until the day in which he was taken up, after that he through the Holy Ghost had given commandments unto the apostles whom he had chosen:"},
  {id: 3, ref: "Act1:3", verse: "To whom also he shewed himself alive after his passion by many infallible proofs, being seen of them forty days, and speaking of the things pertaining to the kingdom of God:"},
  {id: 4, ref: "Act1:4", verse: "And, being assembled together with them, commanded them that they should not depart from Jerusalem, but wait for the promise of the Father, which, saith he, ye have heard of me."},
  {id: 5, ref: "Act1:5", verse: "For John truly baptized with water; but ye shall be baptized with the Holy Ghost not many days hence."},
  {id: 6, ref: "Act1:6", verse: "When they therefore were come together, they asked of him, saying, Lord, wilt thou at this time restore again the kingdom to Israel?"},
  {id: 7, ref: "Act1:7", verse: "And he said unto them, It is not for you to know the times or the seasons, which the Father hath put in his own power."},
  {id: 8, ref: "Act1:8", verse: "But ye shall receive power, after that the Holy Ghost is come upon you: and ye shall be witnesses unto me both in Jerusalem, and in all Judaea, and in Samaria, and unto the uttermost part of the earth."},
  {id: 9, ref: "Act1:9", verse: "And when he had spoken these things, while they beheld, he was taken up; and a cloud received him out of their sight."},
  {id: 10, ref: "Act1:10", verse: "And while they looked stedfastly toward heaven as he went up, behold, two men stood by them in white apparel;"},
  {id: 11, ref: "Act1:11", verse: "Which also said, Ye men of Galilee, why stand ye gazing up into heaven? this same Jesus, which is taken up from you into heaven, shall so come in like manner as ye have seen him go into heaven."},
  {id: 12, ref: "Act1:12", verse: "Then returned they unto Jerusalem from the mount called Olivet, which is from Jerusalem a sabbath day's journey."},
  {id: 13, ref: "Act1:13", verse: "And when they were come in, they went up into an upper room, where abode both Peter, and James, and John, and Andrew, Philip, and Thomas, Bartholomew, and Matthew, James the son of Alphaeus, and Simon Zelotes, and Judas the brother of James."},
  {id: 14, ref: "Act1:14", verse: "These all continued with one accord in prayer and supplication, with the women, and Mary the mother of Jesus, and with his brethren."},
  {id: 15, ref: "Act1:15", verse: "And in those days Peter stood up in the midst of the disciples, and said, (the number of names together were about an hundred and twenty,)"},
  {id: 16, ref: "Act1:16", verse: "Men and brethren, this scripture must needs have been fulfilled, which the Holy Ghost by the mouth of David spake before concerning Judas, which was guide to them that took Jesus."},
  {id: 17, ref: "Act1:17", verse: "For he was numbered with us, and had obtained part of this ministry."},
  {id: 18, ref: "Act1:18", verse: "Now this man purchased a field with the reward of iniquity; and falling headlong, he burst asunder in the midst, and all his bowels gushed out."},
  {id: 19, ref: "Act1:19", verse: "And it was known unto all the dwellers at Jerusalem; insomuch as that field is called in their proper tongue, Aceldama, that is to say, The field of blood."},
  {id: 20, ref: "Act1:20", verse: "For it is written in the book of Psalms, Let his habitation be desolate, and let no man dwell therein: and his bishoprick let another take."},
  {id: 21, ref: "Act1:21", verse: "Wherefore of these men which have companied with us all the time that the Lord Jesus went in and out among us,"},
  {id: 22, ref: "Act1:22", verse: "Beginning from the baptism of John, unto that same day that he was taken up from us, must one be ordained to be a witness with us of his resurrection."},
  {id: 23, ref: "Act1:23", verse: "And they appointed two, Joseph called Barsabas, who was surnamed Justus, and Matthias."},
  {id: 24, ref: "Act1:24", verse: "And they prayed, and said, Thou, Lord, which knowest the hearts of all men, shew whether of these two thou hast chosen,"},
  {id: 25, ref: "Act1:25", verse: "That he may take part of this ministry and apostleship, from which Judas by transgression fell, that he might go to his own place."},
  {id: 26, ref: "Act1:26", verse: "And they gave forth their lots; and the lot fell upon Matthias; and he was numbered with the eleven apostles."},
  {id: 27, ref: "Act2:1", verse: "And when the day of Pentecost was fully come, they were all with one accord in one place."},
  {id: 28, ref: "Act2:2", verse: "And suddenly there came a sound from heaven as of a rushing mighty wind, and it filled all the house where they were sitting."},
  {id: 29, ref: "Act2:3", verse: "And there appeared unto them cloven tongues like as of fire, and it sat upon each of them."},
  {id: 30, ref: "Act2:4", verse: "And they were all filled with the Holy Ghost, and began to speak with other tongues, as the Spirit gave them utterance."},
  {id: 31, ref: "Act2:5", verse: "And there were dwelling at Jerusalem Jews, devout men, out of every nation under heaven."},
  {id: 32, ref: "Act2:6", verse: "Now when this was noised abroad, the multitude came together, and were confounded, because that every man heard them speak in his own language."},
  {id: 33, ref: "Act2:7", verse: "And they were all amazed and marvelled, saying one to another, Behold, are not all these which speak Galilaeans?"},
  {id: 34, ref: "Act2:8", verse: "And how hear we every man in our own tongue, wherein we were born?"},
  {id: 35, ref: "Act2:9", verse: "Parthians, and Medes, and Elamites, and the dwellers in Mesopotamia, and in Judaea, and Cappadocia, in Pontus, and Asia,"},
  {id: 36, ref: "Act2:10", verse: "Phrygia, and Pamphylia, in Egypt, and in the parts of Libya about Cyrene, and strangers of Rome, Jews and proselytes,"},
  {id: 37, ref: "Act2:11", verse: "Cretes and Arabians, we do hear them speak in our tongues the wonderful works of God."},
  {id: 38, ref: "Act2:12", verse: "And they were all amazed, and were in doubt, saying one to another, What meaneth this?"},
  {id: 39, ref: "Act2:13", verse: "Others mocking said, These men are full of new wine."},
  {id: 40, ref: "Act2:14", verse: "But Peter, standing up with the eleven, lifted up his voice, and said unto them, Ye men of Judaea, and all ye that dwell at Jerusalem, be this known unto you, and hearken to my words:"},
  {id: 41, ref: "Act2:15", verse: "For these are not drunken, as ye suppose, seeing it is but the third hour of the day."},
  {id: 42, ref: "Act2:16", verse: "But this is that which was spoken by the prophet Joel;"},
  {id: 43, ref: "Act2:17", verse: "And it shall come to pass in the last days, saith God, I will pour out of my Spirit upon all flesh: and your sons and your daughters shall prophesy, and your young men shall see visions, and your old men shall dream dreams:"},
  {id: 44, ref: "Act2:18", verse: "And on my servants and on my handmaidens I will pour out in those days of my Spirit; and they shall prophesy:"},
  {id: 45, ref: "Act2:19", verse: "And I will shew wonders in heaven above, and signs in the earth beneath; blood, and fire, and vapour of smoke:"},
  {id: 46, ref: "Act2:20", verse: "The sun shall be turned into darkness, and the moon into blood, before that great and notable day of the Lord come:"},
  {id: 47, ref: "Act2:21", verse: "And it shall come to pass, that whosoever shall call on the name of the Lord shall be saved."},
  {id: 48, ref: "Act2:22", verse: "Ye men of Israel, hear these words; Jesus of Nazareth, a man approved of God among you by miracles and wonders and signs, which God did by him in the midst of you, as ye yourselves also know:"},
  {id: 49, ref: "Act2:23", verse: "Him, being delivered by the determinate counsel and foreknowledge of God, ye have taken, and by wicked hands have crucified and slain:"},
  {id: 50, ref: "Act2:24", verse: "Whom God hath raised up, having loosed the pains of death: because it was not possible that he should be holden of it."},
  {id: 51, ref: "Act2:25", verse: "For David speaketh concerning him, I foresaw the Lord always before my face, for he is on my right hand, that I should not be moved:"},
  {id: 52, ref: "Act2:26", verse: "Therefore did my heart rejoice, and my tongue was glad; moreover also my flesh shall rest in hope:"},
  {id: 53, ref: "Act2:27", verse: "Because thou wilt not leave my soul in hell, neither wilt thou suffer thine Holy One to see corruption."},
  {id: 54, ref: "Act2:28", verse: "Thou hast made known to me the ways of life; thou shalt make me full of joy with thy countenance."},
  {id: 55, ref: "Act2:29", verse: "Men and brethren, let me freely speak unto you of the patriarch David, that he is both dead and buried, and his sepulchre is with us unto this day."},
  {id: 56, ref: "Act2:30", verse: "Therefore being a prophet, and knowing that God had sworn with an oath to him, that of the fruit of his loins, according to the flesh, he would raise up Christ to sit on his throne;"},
  {id: 57, ref: "Act2:31", verse: "He seeing this before spake of the resurrection of Christ, that his soul was not left in hell, neither his flesh did see corruption."},
  {id: 58, ref: "Act2:32", verse: "This Jesus hath God raised up, whereof we all are witnesses."},
  {id: 59, ref: "Act2:33", verse: "Therefore being by the right hand of God exalted, and having received of the Father the promise of the Holy Ghost, he hath shed forth this, which ye now see and hear."},
  {id: 60, ref: "Act2:34", verse: "For David is not ascended into the heavens: but he saith himself, The LORD said unto my Lord, Sit thou on my right hand,"},
  {id: 61, ref: "Act2:35", verse: "Until I make thy foes thy footstool."},
  {id: 62, ref: "Act2:36", verse: "Therefore let all the house of Israel know assuredly, that God hath made that same Jesus, whom ye have crucified, both Lord and Christ."},
  {id: 63, ref: "Act2:37", verse: "Now when they heard this, they were pricked in their heart, and said unto Peter and to the rest of the apostles, Men and brethren, what shall we do?"},
  {id: 64, ref: "Act2:38", verse: "Then Peter said unto them, Repent, and be baptized every one of you in the name of Jesus Christ for the remission of sins, and ye shall receive the gift of the Holy Ghost."},
  {id: 65, ref: "Act2:39", verse: "For the promise is unto you, and to your children, and to all that are afar off, even as many as the Lord our God shall call."},
  {id: 66, ref: "Act2:40", verse: "And with many other words did he testify and exhort, saying, Save yourselves from this untoward generation."},
  {id: 67, ref: "Act2:41", verse: "Then they that gladly received his word were baptized: and the same day there were added unto them about three thousand souls."},
  {id: 68, ref: "Act2:42", verse: "And they continued stedfastly in the apostles' doctrine and fellowship, and in breaking of bread, and in prayers."},
  {id: 69, ref: "Act2:43", verse: "And fear came upon every soul: and many wonders and signs were done by the apostles."},
  {id: 70, ref: "Act2:44", verse: "And all that believed were together, and had all things common;"},
  {id: 71, ref: "Act2:45", verse: "And sold their possessions and goods, and parted them to all men, as every man had need."},
  {id: 72, ref: "Act2:46", verse: "And they, continuing daily with one accord in the temple, and breaking bread from house to house, did eat their meat with gladness and singleness of heart,"},
  {id: 73, ref: "Act2:47", verse: "Praising God, and having favour with all the people. And the Lord added to the church daily such as should be saved."},
  {id: 74, ref: "Act3:1", verse: "Now Peter and John went up together into the temple at the hour of prayer, being the ninth hour."},
  {id: 75, ref: "Act3:2", verse: "And a certain man lame from his mother's womb was carried, whom they laid daily at the gate of the temple which is called Beautiful, to ask alms of them that entered into the temple;"},
  {id: 76, ref: "Act3:3", verse: "Who seeing Peter and John about to go into the temple asked an alms."},
  {id: 77, ref: "Act3:4", verse: "And Peter, fastening his eyes upon him with John, said, Look on us."},
  {id: 78, ref: "Act3:5", verse: "And he gave heed unto them, expecting to receive something of them."},
  {id: 79, ref: "Act3:6", verse: "Then Peter said, Silver and gold have I none; but such as I have give I thee: In the name of Jesus Christ of Nazareth rise up and walk."},
  {id: 80, ref: "Act3:7", verse: "And he took him by the right hand, and lifted him up: and immediately his feet and ankle bones received strength."},
  {id: 81, ref: "Act3:8", verse: "And he leaping up stood, and walked, and entered with them into the temple, walking, and leaping, and praising God."},
  {id: 82, ref: "Act3:9", verse: "And all the people saw him walking and praising God:"},
  {id: 83, ref: "Act3:10", verse: "And they knew that it was he which sat for alms at the Beautiful gate of the temple: and they were filled with wonder and amazement at that which had happened unto him."},
  {id: 84, ref: "Act3:11", verse: "And as the lame man which was healed held Peter and John, all the people ran together unto them in the porch that is called Solomon's, greatly wondering."},
  {id: 85, ref: "Act3:12", verse: "And when Peter saw it, he answered unto the people, Ye men of Israel, why marvel ye at this? or why look ye so earnestly on us, as though by our own power or holiness we had made this man to walk?"},
  {id: 86, ref: "Act3:13", verse: "The God of Abraham, and of Isaac, and of Jacob, the God of our fathers, hath glorified his Son Jesus; whom ye delivered up, and denied him in the presence of Pilate, when he was determined to let him go."},
  {id: 87, ref: "Act3:14", verse: "But ye denied the Holy One and the Just, and desired a murderer to be granted unto you;"},
  {id: 88, ref: "Act3:15", verse: "And killed the Prince of life, whom God hath raised from the dead; whereof we are witnesses."},
  {id: 89, ref: "Act3:16", verse: "And his name through faith in his name hath made this man strong, whom ye see and know: yea, the faith which is by him hath given him this perfect soundness in the presence of you all."},
  {id: 90, ref: "Act3:17", verse: "And now, brethren, I wot that through ignorance ye did it, as did also your rulers."},
  {id: 91, ref: "Act3:18", verse: "But those things, which God before had shewed by the mouth of all his prophets, that Christ should suffer, he hath so fulfilled."},
  {id: 92, ref: "Act3:19", verse: "Repent ye therefore, and be converted, that your sins may be blotted out, when the times of refreshing shall come from the presence of the Lord;"},
  {id: 93, ref: "Act3:20", verse: "And he shall send Jesus Christ, which before was preached unto you:"},
  {id: 94, ref: "Act3:21", verse: "Whom the heaven must receive until the times of restitution of all things, which God hath spoken by the mouth of all his holy prophets since the world began."},
  {id: 95, ref: "Act3:22", verse: "For Moses truly said unto the fathers, A prophet shall the Lord your God raise up unto you of your brethren, like unto me; him shall ye hear in all things whatsoever he shall say unto you."},
  {id: 96, ref: "Act3:23", verse: "And it shall come to pass, that every soul, which will not hear that prophet, shall be destroyed from among the people."},
  {id: 97, ref: "Act3:24", verse: "Yea, and all the prophets from Samuel and those that follow after, as many as have spoken, have likewise foretold of these days."},
  {id: 98, ref: "Act3:25", verse: "Ye are the children of the prophets, and of the covenant which God made with our fathers, saying unto Abraham, And in thy seed shall all the kindreds of the earth be blessed."},
  {id: 99, ref: "Act3:26", verse: "Unto you first God, having raised up his Son Jesus, sent him to bless you, in turning away every one of you from his iniquities."},
  {id: 100, ref: "Act4:1", verse: "And as they spake unto the people, the priests, and the captain of the temple, and the Sadducees, came upon them,"},
  {id: 101, ref: "Act4:2", verse: "Being grieved that they taught the people, and preached through Jesus the resurrection from the dead."},
  {id: 102, ref: "Act4:3", verse: "And they laid hands on them, and put them in hold unto the next day: for it was now eventide."},
  {id: 103, ref: "Act4:4", verse: "Howbeit many of them which heard the word believed; and the number of the men was about five thousand."},
  {id: 104, ref: "Act4:5", verse: "And it came to pass on the morrow, that their rulers, and elders, and scribes,"},
  {id: 105, ref: "Act4:6", verse: "And Annas the high priest, and Caiaphas, and John, and Alexander, and as many as were of the kindred of the high priest, were gathered together at Jerusalem."},
  {id: 106, ref: "Act4:7", verse: "And when they had set them in the midst, they asked, By what power, or by what name, have ye done this?"},
  {id: 107, ref: "Act4:8", verse: "Then Peter, filled with the Holy Ghost, said unto them, Ye rulers of the people, and elders of Israel,"},
  {id: 108, ref: "Act4:9", verse: "If we this day be examined of the good deed done to the impotent man, by what means he is made whole;"},
  {id: 109, ref: "Act4:10", verse: "Be it known unto you all, and to all the people of Israel, that by the name of Jesus Christ of Nazareth, whom ye crucified, whom God raised from the dead, even by him doth this man stand here before you whole."},
  {id: 110, ref: "Act4:11", verse: "This is the stone which was set at nought of you builders, which is become the head of the corner."},
  {id: 111, ref: "Act4:12", verse: "Neither is there salvation in any other: for there is none other name under heaven given among men, whereby we must be saved."},
  {id: 112, ref: "Act4:13", verse: "Now when they saw the boldness of Peter and John, and perceived that they were unlearned and ignorant men, they marvelled; and they took knowledge of them, that they had been with Jesus."},
  {id: 113, ref: "Act4:14", verse: "And beholding the man which was healed standing with them, they could say nothing against it."},
  {id: 114, ref: "Act4:15", verse: "But when they had commanded them to go aside out of the council, they conferred among themselves,"},
  {id: 115, ref: "Act4:16", verse: "Saying, What shall we do to these men? for that indeed a notable miracle hath been done by them is manifest to all them that dwell in Jerusalem; and we cannot deny it."},
  {id: 116, ref: "Act4:17", verse: "But that it spread no further among the people, let us straitly threaten them, that they speak henceforth to no man in this name."},
  {id: 117, ref: "Act4:18", verse: "And they called them, and commanded them not to speak at all nor teach in the name of Jesus."},
  {id: 118, ref: "Act4:19", verse: "But Peter and John answered and said unto them, Whether it be right in the sight of God to hearken unto you more than unto God, judge ye."},
  {id: 119, ref: "Act4:20", verse: "For we cannot but speak the things which we have seen and heard."},
  {id: 120, ref: "Act4:21", verse: "So when they had further threatened them, they let them go, finding nothing how they might punish them, because of the people: for all men glorified God for that which was done."},
  {id: 121, ref: "Act4:22", verse: "For the man was above forty years old, on whom this miracle of healing was shewed."},
  {id: 122, ref: "Act4:23", verse: "And being let go, they went to their own company, and reported all that the chief priests and elders had said unto them."},
  {id: 123, ref: "Act4:24", verse: "And when they heard that, they lifted up their voice to God with one accord, and said, Lord, thou art God, which hast made heaven, and earth, and the sea, and all that in them is:"},
  {id: 124, ref: "Act4:25", verse: "Who by the mouth of thy servant David hast said, Why did the heathen rage, and the people imagine vain things?"},
  {id: 125, ref: "Act4:26", verse: "The kings of the earth stood up, and the rulers were gathered together against the Lord, and against his Christ."},
  {id: 126, ref: "Act4:27", verse: "For of a truth against thy holy child Jesus, whom thou hast anointed, both Herod, and Pontius Pilate, with the Gentiles, and the people of Israel, were gathered together,"},
  {id: 127, ref: "Act4:28", verse: "For to do whatsoever thy hand and thy counsel determined before to be done."},
  {id: 128, ref: "Act4:29", verse: "And now, Lord, behold their threatenings: and grant unto thy servants, that with all boldness they may speak thy word,"},
  {id: 129, ref: "Act4:30", verse: "By stretching forth thine hand to heal; and that signs and wonders may be done by the name of thy holy child Jesus."},
  {id: 130, ref: "Act4:31", verse: "And when they had prayed, the place was shaken where they were assembled together; and they were all filled with the Holy Ghost, and they spake the word of God with boldness."},
  {id: 131, ref: "Act4:32", verse: "And the multitude of them that believed were of one heart and of one soul: neither said any of them that ought of the things which he possessed was his own; but they had all things common."},
  {id: 132, ref: "Act4:33", verse: "And with great power gave the apostles witness of the resurrection of the Lord Jesus: and great grace was upon them all."},
  {id: 133, ref: "Act4:34", verse: "Neither was there any among them that lacked: for as many as were possessors of lands or houses sold them, and brought the prices of the things that were sold,"},
  {id: 134, ref: "Act4:35", verse: "And laid them down at the apostles' feet: and distribution was made unto every man according as he had need."},
  {id: 135, ref: "Act4:36", verse: "And Joses, who by the apostles was surnamed Barnabas, (which is, being interpreted, The son of consolation,) a Levite, and of the country of Cyprus,"},
  {id: 136, ref: "Act4:37", verse: "Having land, sold it, and brought the money, and laid it at the apostles' feet."},
  {id: 137, ref: "Act5:1", verse: "But a certain man named Ananias, with Sapphira his wife, sold a possession,"},
  {id: 138, ref: "Act5:2", verse: "And kept back part of the price, his wife also being privy to it, and brought a certain part, and laid it at the apostles' feet."},
  {id: 139, ref: "Act5:3", verse: "But Peter said, Ananias, why hath Satan filled thine heart to lie to the Holy Ghost, and to keep back part of the price of the land?"},
  {id: 140, ref: "Act5:4", verse: "Whiles it remained, was it not thine own? and after it was sold, was it not in thine own power? why hast thou conceived this thing in thine heart? thou hast not lied unto men, but unto God."},
  {id: 141, ref: "Act5:5", verse: "And Ananias hearing these words fell down, and gave up the ghost: and great fear came on all them that heard these things."},
  {id: 142, ref: "Act5:6", verse: "And the young men arose, wound him up, and carried him out, and buried him."},
  {id: 143, ref: "Act5:7", verse: "And it was about the space of three hours after, when his wife, not knowing what was done, came in."},
  {id: 144, ref: "Act5:8", verse: "And Peter answered unto her, Tell me whether ye sold the land for so much? And she said, Yea, for so much."},
  {id: 145, ref: "Act5:9", verse: "Then Peter said unto her, How is it that ye have agreed together to tempt the Spirit of the Lord? behold, the feet of them which have buried thy husband are at the door, and shall carry thee out."},
  {id: 146, ref: "Act5:10", verse: "Then fell she down straightway at his feet, and yielded up the ghost: and the young men came in, and found her dead, and, carrying her forth, buried her by her husband."},
  {id: 147, ref: "Act5:11", verse: "And great fear came upon all the church, and upon as many as heard these things."},
  {id: 148, ref: "Act5:12", verse: "And by the hands of the apostles were many signs and wonders wrought among the people; (and they were all with one accord in Solomon's porch."},
  {id: 149, ref: "Act5:13", verse: "And of the rest durst no man join himself to them: but the people magnified them."},
  {id: 150, ref: "Act5:14", verse: "And believers were the more added to the Lord, multitudes both of men and women.)"},
  {id: 151, ref: "Act5:15", verse: "Insomuch that they brought forth the sick into the streets, and laid them on beds and couches, that at the least the shadow of Peter passing by might overshadow some of them."},
  {id: 152, ref: "Act5:16", verse: "There came also a multitude out of the cities round about unto Jerusalem, bringing sick folks, and them which were vexed with unclean spirits: and they were healed every one."},
  {id: 153, ref: "Act5:17", verse: "Then the high priest rose up, and all they that were with him, (which is the sect of the Sadducees,) and were filled with indignation,"},
  {id: 154, ref: "Act5:18", verse: "And laid their hands on the apostles, and put them in the common prison."},
  {id: 155, ref: "Act5:19", verse: "But the angel of the Lord by night opened the prison doors, and brought them forth, and said,"},
  {id: 156, ref: "Act5:20", verse: "Go, stand and speak in the temple to the people all the words of this life."},
  {id: 157, ref: "Act5:21", verse: "And when they heard that, they entered into the temple early in the morning, and taught. But the high priest came, and they that were with him, and called the council together, and all the senate of the children of Israel, and sent to the prison to have them brought."},
  {id: 158, ref: "Act5:22", verse: "But when the officers came, and found them not in the prison, they returned, and told,"},
  {id: 159, ref: "Act5:23", verse: "Saying, The prison truly found we shut with all safety, and the keepers standing without before the doors: but when we had opened, we found no man within."},
  {id: 160, ref: "Act5:24", verse: "Now when the high priest and the captain of the temple and the chief priests heard these things, they doubted of them whereunto this would grow."},
  {id: 161, ref: "Act5:25", verse: "Then came one and told them, saying, Behold, the men whom ye put in prison are standing in the temple, and teaching the people."},
  {id: 162, ref: "Act5:26", verse: "Then went the captain with the officers, and brought them without violence: for they feared the people, lest they should have been stoned."},
  {id: 163, ref: "Act5:27", verse: "And when they had brought them, they set them before the council: and the high priest asked them,"},
  {id: 164, ref: "Act5:28", verse: "Saying, Did not we straitly command you that ye should not teach in this name? and, behold, ye have filled Jerusalem with your doctrine, and intend to bring this man's blood upon us."},
  {id: 165, ref: "Act5:29", verse: "Then Peter and the other apostles answered and said, We ought to obey God rather than men."},
  {id: 166, ref: "Act5:30", verse: "The God of our fathers raised up Jesus, whom ye slew and hanged on a tree."},
  {id: 167, ref: "Act5:31", verse: "Him hath God exalted with his right hand to be a Prince and a Saviour, for to give repentance to Israel, and forgiveness of sins."},
  {id: 168, ref: "Act5:32", verse: "And we are his witnesses of these things; and so is also the Holy Ghost, whom God hath given to them that obey him."},
  {id: 169, ref: "Act5:33", verse: "When they heard that, they were cut to the heart, and took counsel to slay them."},
  {id: 170, ref: "Act5:34", verse: "Then stood there up one in the council, a Pharisee, named Gamaliel, a doctor of the law, had in reputation among all the people, and commanded to put the apostles forth a little space;"},
  {id: 171, ref: "Act5:35", verse: "And said unto them, Ye men of Israel, take heed to yourselves what ye intend to do as touching these men."},
  {id: 172, ref: "Act5:36", verse: "For before these days rose up Theudas, boasting himself to be somebody; to whom a number of men, about four hundred, joined themselves: who was slain; and all, as many as obeyed him, were scattered, and brought to nought."},
  {id: 173, ref: "Act5:37", verse: "After this man rose up Judas of Galilee in the days of the taxing, and drew away much people after him: he also perished; and all, even as many as obeyed him, were dispersed."},
  {id: 174, ref: "Act5:38", verse: "And now I say unto you, Refrain from these men, and let them alone: for if this counsel or this work be of men, it will come to nought:"},
  {id: 175, ref: "Act5:39", verse: "But if it be of God, ye cannot overthrow it; lest haply ye be found even to fight against God."},
  {id: 176, ref: "Act5:40", verse: "And to him they agreed: and when they had called the apostles, and beaten them, they commanded that they should not speak in the name of Jesus, and let them go."},
  {id: 177, ref: "Act5:41", verse: "And they departed from the presence of the council, rejoicing that they were counted worthy to suffer shame for his name."},
  {id: 178, ref: "Act5:42", verse: "And daily in the temple, and in every house, they ceased not to teach and preach Jesus Christ."},
  {id: 179, ref: "Act6:1", verse: "And in those days, when the number of the disciples was multiplied, there arose a murmuring of the Grecians against the Hebrews, because their widows were neglected in the daily ministration."},
  {id: 180, ref: "Act6:2", verse: "Then the twelve called the multitude of the disciples unto them, and said, It is not reason that we should leave the word of God, and serve tables."},
  {id: 181, ref: "Act6:3", verse: "Wherefore, brethren, look ye out among you seven men of honest report, full of the Holy Ghost and wisdom, whom we may appoint over this business."},
  {id: 182, ref: "Act6:4", verse: "But we will give ourselves continually to prayer, and to the ministry of the word."},
  {id: 183, ref: "Act6:5", verse: "And the saying pleased the whole multitude: and they chose Stephen, a man full of faith and of the Holy Ghost, and Philip, and Prochorus, and Nicanor, and Timon, and Parmenas, and Nicolas a proselyte of Antioch:"},
  {id: 184, ref: "Act6:6", verse: "Whom they set before the apostles: and when they had prayed, they laid their hands on them."},
  {id: 185, ref: "Act6:7", verse: "And the word of God increased; and the number of the disciples multiplied in Jerusalem greatly; and a great company of the priests were obedient to the faith."},
  {id: 186, ref: "Act6:8", verse: "And Stephen, full of faith and power, did great wonders and miracles among the people."},
  {id: 187, ref: "Act6:9", verse: "Then there arose certain of the synagogue, which is called the synagogue of the Libertines, and Cyrenians, and Alexandrians, and of them of Cilicia and of Asia, disputing with Stephen."},
  {id: 188, ref: "Act6:10", verse: "And they were not able to resist the wisdom and the spirit by which he spake."},
  {id: 189, ref: "Act6:11", verse: "Then they suborned men, which said, We have heard him speak blasphemous words against Moses, and against God."},
  {id: 190, ref: "Act6:12", verse: "And they stirred up the people, and the elders, and the scribes, and came upon him, and caught him, and brought him to the council,"},
  {id: 191, ref: "Act6:13", verse: "And set up false witnesses, which said, This man ceaseth not to speak blasphemous words against this holy place, and the law:"},
  {id: 192, ref: "Act6:14", verse: "For we have heard him say, that this Jesus of Nazareth shall destroy this place, and shall change the customs which Moses delivered us."},
  {id: 193, ref: "Act6:15", verse: "And all that sat in the council, looking stedfastly on him, saw his face as it had been the face of an angel."},
  {id: 194, ref: "Act7:1", verse: "Then said the high priest, Are these things so?"},
  {id: 195, ref: "Act7:2", verse: "And he said, Men, brethren, and fathers, hearken; The God of glory appeared unto our father Abraham, when he was in Mesopotamia, before he dwelt in Charran,"},
  {id: 196, ref: "Act7:3", verse: "And said unto him, Get thee out of thy country, and from thy kindred, and come into the land which I shall shew thee."},
  {id: 197, ref: "Act7:4", verse: "Then came he out of the land of the Chaldaeans, and dwelt in Charran: and from thence, when his father was dead, he removed him into this land, wherein ye now dwell."},
  {id: 198, ref: "Act7:5", verse: "And he gave him none inheritance in it, no, not so much as to set his foot on: yet he promised that he would give it to him for a possession, and to his seed after him, when as yet he had no child."},
  {id: 199, ref: "Act7:6", verse: "And God spake on this wise, That his seed should sojourn in a strange land; and that they should bring them into bondage, and entreat them evil four hundred years."},
  {id: 200, ref: "Act7:7", verse: "And the nation to whom they shall be in bondage will I judge, said God: and after that shall they come forth, and serve me in this place."},
  {id: 201, ref: "Act7:8", verse: "And he gave him the covenant of circumcision: and so Abraham begat Isaac, and circumcised him the eighth day; and Isaac begat Jacob; and Jacob begat the twelve patriarchs."},
  {id: 202, ref: "Act7:9", verse: "And the patriarchs, moved with envy, sold Joseph into Egypt: but God was with him,"},
  {id: 203, ref: "Act7:10", verse: "And delivered him out of all his afflictions, and gave him favour and wisdom in the sight of Pharaoh king of Egypt; and he made him governor over Egypt and all his house."},
  {id: 204, ref: "Act7:11", verse: "Now there came a dearth over all the land of Egypt and Chanaan, and great affliction: and our fathers found no sustenance."},
  {id: 205, ref: "Act7:12", verse: "But when Jacob heard that there was corn in Egypt, he sent out our fathers first."},
  {id: 206, ref: "Act7:13", verse: "And at the second time Joseph was made known to his brethren; and Joseph's kindred was made known unto Pharaoh."},
  {id: 207, ref: "Act7:14", verse: "Then sent Joseph, and called his father Jacob to him, and all his kindred, threescore and fifteen souls."},
  {id: 208, ref: "Act7:15", verse: "So Jacob went down into Egypt, and died, he, and our fathers,"},
  {id: 209, ref: "Act7:16", verse: "And were carried over into Sychem, and laid in the sepulchre that Abraham bought for a sum of money of the sons of Emmor the father of Sychem."},
  {id: 210, ref: "Act7:17", verse: "But when the time of the promise drew nigh, which God had sworn to Abraham, the people grew and multiplied in Egypt,"},
  {id: 211, ref: "Act7:18", verse: "Till another king arose, which knew not Joseph."},
  {id: 212, ref: "Act7:19", verse: "The same dealt subtilly with our kindred, and evil entreated our fathers, so that they cast out their young children, to the end they might not live."},
  {id: 213, ref: "Act7:20", verse: "In which time Moses was born, and was exceeding fair, and nourished up in his father's house three months:"},
  {id: 214, ref: "Act7:21", verse: "And when he was cast out, Pharaoh's daughter took him up, and nourished him for her own son."},
  {id: 215, ref: "Act7:22", verse: "And Moses was learned in all the wisdom of the Egyptians, and was mighty in words and in deeds."},
  {id: 216, ref: "Act7:23", verse: "And when he was full forty years old, it came into his heart to visit his brethren the children of Israel."},
  {id: 217, ref: "Act7:24", verse: "And seeing one of them suffer wrong, he defended him, and avenged him that was oppressed, and smote the Egyptian:"},
  {id: 218, ref: "Act7:25", verse: "For he supposed his brethren would have understood how that God by his hand would deliver them: but they understood not."},
  {id: 219, ref: "Act7:26", verse: "And the next day he shewed himself unto them as they strove, and would have set them at one again, saying, Sirs, ye are brethren; why do ye wrong one to another?"},
  {id: 220, ref: "Act7:27", verse: "But he that did his neighbour wrong thrust him away, saying, Who made thee a ruler and a judge over us?"},
  {id: 221, ref: "Act7:28", verse: "Wilt thou kill me, as thou diddest the Egyptian yesterday?"},
  {id: 222, ref: "Act7:29", verse: "Then fled Moses at this saying, and was a stranger in the land of Madian, where he begat two sons."},
  {id: 223, ref: "Act7:30", verse: "And when forty years were expired, there appeared to him in the wilderness of mount Sina an angel of the Lord in a flame of fire in a bush."},
  {id: 224, ref: "Act7:31", verse: "When Moses saw it, he wondered at the sight: and as he drew near to behold it, the voice of the Lord came unto him,"},
  {id: 225, ref: "Act7:32", verse: "Saying, I am the God of thy fathers, the God of Abraham, and the God of Isaac, and the God of Jacob. Then Moses trembled, and durst not behold."},
  {id: 226, ref: "Act7:33", verse: "Then said the Lord to him, Put off thy shoes from thy feet: for the place where thou standest is holy ground."},
  {id: 227, ref: "Act7:34", verse: "I have seen, I have seen the affliction of my people which is in Egypt, and I have heard their groaning, and am come down to deliver them. And now come, I will send thee into Egypt."},
  {id: 228, ref: "Act7:35", verse: "This Moses whom they refused, saying, Who made thee a ruler and a judge? the same did God send to be a ruler and a deliverer by the hand of the angel which appeared to him in the bush."},
  {id: 229, ref: "Act7:36", verse: "He brought them out, after that he had shewed wonders and signs in the land of Egypt, and in the Red sea, and in the wilderness forty years."},
  {id: 230, ref: "Act7:37", verse: "This is that Moses, which said unto the children of Israel, A prophet shall the Lord your God raise up unto you of your brethren, like unto me; him shall ye hear."},
  {id: 231, ref: "Act7:38", verse: "This is he, that was in the church in the wilderness with the angel which spake to him in the mount Sina, and with our fathers: who received the lively oracles to give unto us:"},
  {id: 232, ref: "Act7:39", verse: "To whom our fathers would not obey, but thrust him from them, and in their hearts turned back again into Egypt,"},
  {id: 233, ref: "Act7:40", verse: "Saying unto Aaron, Make us gods to go before us: for as for this Moses, which brought us out of the land of Egypt, we wot not what is become of him."},
  {id: 234, ref: "Act7:41", verse: "And they made a calf in those days, and offered sacrifice unto the idol, and rejoiced in the works of their own hands."},
  {id: 235, ref: "Act7:42", verse: "Then God turned, and gave them up to worship the host of heaven; as it is written in the book of the prophets, O ye house of Israel, have ye offered to me slain beasts and sacrifices by the space of forty years in the wilderness?"},
  {id: 236, ref: "Act7:43", verse: "Yea, ye took up the tabernacle of Moloch, and the star of your god Remphan, figures which ye made to worship them: and I will carry you away beyond Babylon."},
  {id: 237, ref: "Act7:44", verse: "Our fathers had the tabernacle of witness in the wilderness, as he had appointed, speaking unto Moses, that he should make it according to the fashion that he had seen."},
  {id: 238, ref: "Act7:45", verse: "Which also our fathers that came after brought in with Jesus into the possession of the Gentiles, whom God drave out before the face of our fathers, unto the days of David;"},
  {id: 239, ref: "Act7:46", verse: "Who found favour before God, and desired to find a tabernacle for the God of Jacob."},
  {id: 240, ref: "Act7:47", verse: "But Solomon built him an house."},
  {id: 241, ref: "Act7:48", verse: "Howbeit the most High dwelleth not in temples made with hands; as saith the prophet,"},
  {id: 242, ref: "Act7:49", verse: "Heaven is my throne, and earth is my footstool: what house will ye build me? saith the Lord: or what is the place of my rest?"},
  {id: 243, ref: "Act7:50", verse: "Hath not my hand made all these things?"},
  {id: 244, ref: "Act7:51", verse: "Ye stiffnecked and uncircumcised in heart and ears, ye do always resist the Holy Ghost: as your fathers did, so do ye."},
  {id: 245, ref: "Act7:52", verse: "Which of the prophets have not your fathers persecuted? and they have slain them which shewed before of the coming of the Just One; of whom ye have been now the betrayers and murderers:"},
  {id: 246, ref: "Act7:53", verse: "Who have received the law by the disposition of angels, and have not kept it."},
  {id: 247, ref: "Act7:54", verse: "When they heard these things, they were cut to the heart, and they gnashed on him with their teeth."},
  {id: 248, ref: "Act7:55", verse: "But he, being full of the Holy Ghost, looked up stedfastly into heaven, and saw the glory of God, and Jesus standing on the right hand of God,"},
  {id: 249, ref: "Act7:56", verse: "And said, Behold, I see the heavens opened, and the Son of man standing on the right hand of God."},
  {id: 250, ref: "Act7:57", verse: "Then they cried out with a loud voice, and stopped their ears, and ran upon him with one accord,"},
  {id: 251, ref: "Act7:58", verse: "And cast him out of the city, and stoned him: and the witnesses laid down their clothes at a young man's feet, whose name was Saul."},
  {id: 252, ref: "Act7:59", verse: "And they stoned Stephen, calling upon God, and saying, Lord Jesus, receive my spirit."},
  {id: 253, ref: "Act7:60", verse: "And he kneeled down, and cried with a loud voice, Lord, lay not this sin to their charge. And when he had said this, he fell asleep."},
  {id: 254, ref: "Act8:1", verse: "And Saul was consenting unto his death. And at that time there was a great persecution against the church which was at Jerusalem; and they were all scattered abroad throughout the regions of Judaea and Samaria, except the apostles."},
  {id: 255, ref: "Act8:2", verse: "And devout men carried Stephen to his burial, and made great lamentation over him."},
  {id: 256, ref: "Act8:3", verse: "As for Saul, he made havock of the church, entering into every house, and haling men and women committed them to prison."},
  {id: 257, ref: "Act8:4", verse: "Therefore they that were scattered abroad went every where preaching the word."},
  {id: 258, ref: "Act8:5", verse: "Then Philip went down to the city of Samaria, and preached Christ unto them."},
  {id: 259, ref: "Act8:6", verse: "And the people with one accord gave heed unto those things which Philip spake, hearing and seeing the miracles which he did."},
  {id: 260, ref: "Act8:7", verse: "For unclean spirits, crying with loud voice, came out of many that were possessed with them: and many taken with palsies, and that were lame, were healed."},
  {id: 261, ref: "Act8:8", verse: "And there was great joy in that city."},
  {id: 262, ref: "Act8:9", verse: "But there was a certain man, called Simon, which beforetime in the same city used sorcery, and bewitched the people of Samaria, giving out that himself was some great one:"},
  {id: 263, ref: "Act8:10", verse: "To whom they all gave heed, from the least to the greatest, saying, This man is the great power of God."},
  {id: 264, ref: "Act8:11", verse: "And to him they had regard, because that of long time he had bewitched them with sorceries."},
  {id: 265, ref: "Act8:12", verse: "But when they believed Philip preaching the things concerning the kingdom of God, and the name of Jesus Christ, they were baptized, both men and women."},
  {id: 266, ref: "Act8:13", verse: "Then Simon himself believed also: and when he was baptized, he continued with Philip, and wondered, beholding the miracles and signs which were done."},
  {id: 267, ref: "Act8:14", verse: "Now when the apostles which were at Jerusalem heard that Samaria had received the word of God, they sent unto them Peter and John:"},
  {id: 268, ref: "Act8:15", verse: "Who, when they were come down, prayed for them, that they might receive the Holy Ghost:"},
  {id: 269, ref: "Act8:16", verse: "(For as yet he was fallen upon none of them: only they were baptized in the name of the Lord Jesus.)"},
  {id: 270, ref: "Act8:17", verse: "Then laid they their hands on them, and they received the Holy Ghost."},
  {id: 271, ref: "Act8:18", verse: "And when Simon saw that through laying on of the apostles' hands the Holy Ghost was given, he offered them money,"},
  {id: 272, ref: "Act8:19", verse: "Saying, Give me also this power, that on whomsoever I lay hands, he may receive the Holy Ghost."},
  {id: 273, ref: "Act8:20", verse: "But Peter said unto him, Thy money perish with thee, because thou hast thought that the gift of God may be purchased with money."},
  {id: 274, ref: "Act8:21", verse: "Thou hast neither part nor lot in this matter: for thy heart is not right in the sight of God."},
  {id: 275, ref: "Act8:22", verse: "Repent therefore of this thy wickedness, and pray God, if perhaps the thought of thine heart may be forgiven thee."},
  {id: 276, ref: "Act8:23", verse: "For I perceive that thou art in the gall of bitterness, and in the bond of iniquity."},
  {id: 277, ref: "Act8:24", verse: "Then answered Simon, and said, Pray ye to the Lord for me, that none of these things which ye have spoken come upon me."},
  {id: 278, ref: "Act8:25", verse: "And they, when they had testified and preached the word of the Lord, returned to Jerusalem, and preached the gospel in many villages of the Samaritans."},
  {id: 279, ref: "Act8:26", verse: "And the angel of the Lord spake unto Philip, saying, Arise, and go toward the south unto the way that goeth down from Jerusalem unto Gaza, which is desert."},
  {id: 280, ref: "Act8:27", verse: "And he arose and went: and, behold, a man of Ethiopia, an eunuch of great authority under Candace queen of the Ethiopians, who had the charge of all her treasure, and had come to Jerusalem for to worship,"},
  {id: 281, ref: "Act8:28", verse: "Was returning, and sitting in his chariot read Esaias the prophet."},
  {id: 282, ref: "Act8:29", verse: "Then the Spirit said unto Philip, Go near, and join thyself to this chariot."},
  {id: 283, ref: "Act8:30", verse: "And Philip ran thither to him, and heard him read the prophet Esaias, and said, Understandest thou what thou readest?"},
  {id: 284, ref: "Act8:31", verse: "And he said, How can I, except some man should guide me? And he desired Philip that he would come up and sit with him."},
  {id: 285, ref: "Act8:32", verse: "The place of the scripture which he read was this, He was led as a sheep to the slaughter; and like a lamb dumb before his shearer, so opened he not his mouth:"},
  {id: 286, ref: "Act8:33", verse: "In his humiliation his judgment was taken away: and who shall declare his generation? for his life is taken from the earth."},
  {id: 287, ref: "Act8:34", verse: "And the eunuch answered Philip, and said, I pray thee, of whom speaketh the prophet this? of himself, or of some other man?"},
  {id: 288, ref: "Act8:35", verse: "Then Philip opened his mouth, and began at the same scripture, and preached unto him Jesus."},
  {id: 289, ref: "Act8:36", verse: "And as they went on their way, they came unto a certain water: and the eunuch said, See, here is water; what doth hinder me to be baptized?"},
  {id: 290, ref: "Act8:37", verse: "And Philip said, If thou believest with all thine heart, thou mayest. And he answered and said, I believe that Jesus Christ is the Son of God."},
  {id: 291, ref: "Act8:38", verse: "And he commanded the chariot to stand still: and they went down both into the water, both Philip and the eunuch; and he baptized him."},
  {id: 292, ref: "Act8:39", verse: "And when they were come up out of the water, the Spirit of the Lord caught away Philip, that the eunuch saw him no more: and he went on his way rejoicing."},
  {id: 293, ref: "Act8:40", verse: "But Philip was found at Azotus: and passing through he preached in all the cities, till he came to Caesarea."},
  {id: 294, ref: "Act9:1", verse: "And Saul, yet breathing out threatenings and slaughter against the disciples of the Lord, went unto the high priest,"},
  {id: 295, ref: "Act9:2", verse: "And desired of him letters to Damascus to the synagogues, that if he found any of this way, whether they were men or women, he might bring them bound unto Jerusalem."},
  {id: 296, ref: "Act9:3", verse: "And as he journeyed, he came near Damascus: and suddenly there shined round about him a light from heaven:"},
  {id: 297, ref: "Act9:4", verse: "And he fell to the earth, and heard a voice saying unto him, Saul, Saul, why persecutest thou me?"},
  {id: 298, ref: "Act9:5", verse: "And he said, Who art thou, Lord? And the Lord said, I am Jesus whom thou persecutest: it is hard for thee to kick against the pricks."},
  {id: 299, ref: "Act9:6", verse: "And he trembling and astonished said, Lord, what wilt thou have me to do? And the Lord said unto him, Arise, and go into the city, and it shall be told thee what thou must do."},
  {id: 300, ref: "Act9:7", verse: "And the men which journeyed with him stood speechless, hearing a voice, but seeing no man."},
  {id: 301, ref: "Act9:8", verse: "And Saul arose from the earth; and when his eyes were opened, he saw no man: but they led him by the hand, and brought him into Damascus."},
  {id: 302, ref: "Act9:9", verse: "And he was three days without sight, and neither did eat nor drink."},
  {id: 303, ref: "Act9:10", verse: "And there was a certain disciple at Damascus, named Ananias; and to him said the Lord in a vision, Ananias. And he said, Behold, I am here, Lord."},
  {id: 304, ref: "Act9:11", verse: "And the Lord said unto him, Arise, and go into the street which is called Straight, and enquire in the house of Judas for one called Saul, of Tarsus: for, behold, he prayeth,"},
  {id: 305, ref: "Act9:12", verse: "And hath seen in a vision a man named Ananias coming in, and putting his hand on him, that he might receive his sight."},
  {id: 306, ref: "Act9:13", verse: "Then Ananias answered, Lord, I have heard by many of this man, how much evil he hath done to thy saints at Jerusalem:"},
  {id: 307, ref: "Act9:14", verse: "And here he hath authority from the chief priests to bind all that call on thy name."},
  {id: 308, ref: "Act9:15", verse: "But the Lord said unto him, Go thy way: for he is a chosen vessel unto me, to bear my name before the Gentiles, and kings, and the children of Israel:"},
  {id: 309, ref: "Act9:16", verse: "For I will shew him how great things he must suffer for my name's sake."},
  {id: 310, ref: "Act9:17", verse: "And Ananias went his way, and entered into the house; and putting his hands on him said, Brother Saul, the Lord, even Jesus, that appeared unto thee in the way as thou camest, hath sent me, that thou mightest receive thy sight, and be filled with the Holy Ghost."},
  {id: 311, ref: "Act9:18", verse: "And immediately there fell from his eyes as it had been scales: and he received sight forthwith, and arose, and was baptized."},
  {id: 312, ref: "Act9:19", verse: "And when he had received meat, he was strengthened. Then was Saul certain days with the disciples which were at Damascus."},
  {id: 313, ref: "Act9:20", verse: "And straightway he preached Christ in the synagogues, that he is the Son of God."},
  {id: 314, ref: "Act9:21", verse: "But all that heard him were amazed, and said; Is not this he that destroyed them which called on this name in Jerusalem, and came hither for that intent, that he might bring them bound unto the chief priests?"},
  {id: 315, ref: "Act9:22", verse: "But Saul increased the more in strength, and confounded the Jews which dwelt at Damascus, proving that this is very Christ."},
  {id: 316, ref: "Act9:23", verse: "And after that many days were fulfilled, the Jews took counsel to kill him:"},
  {id: 317, ref: "Act9:24", verse: "But their laying await was known of Saul. And they watched the gates day and night to kill him."},
  {id: 318, ref: "Act9:25", verse: "Then the disciples took him by night, and let him down by the wall in a basket."},
  {id: 319, ref: "Act9:26", verse: "And when Saul was come to Jerusalem, he assayed to join himself to the disciples: but they were all afraid of him, and believed not that he was a disciple."},
  {id: 320, ref: "Act9:27", verse: "But Barnabas took him, and brought him to the apostles, and declared unto them how he had seen the Lord in the way, and that he had spoken to him, and how he had preached boldly at Damascus in the name of Jesus."},
  {id: 321, ref: "Act9:28", verse: "And he was with them coming in and going out at Jerusalem."},
  {id: 322, ref: "Act9:29", verse: "And he spake boldly in the name of the Lord Jesus, and disputed against the Grecians: but they went about to slay him."},
  {id: 323, ref: "Act9:30", verse: "Which when the brethren knew, they brought him down to Caesarea, and sent him forth to Tarsus."},
  {id: 324, ref: "Act9:31", verse: "Then had the churches rest throughout all Judaea and Galilee and Samaria, and were edified; and walking in the fear of the Lord, and in the comfort of the Holy Ghost, were multiplied."},
  {id: 325, ref: "Act9:32", verse: "And it came to pass, as Peter passed throughout all quarters, he came down also to the saints which dwelt at Lydda."},
  {id: 326, ref: "Act9:33", verse: "And there he found a certain man named Aeneas, which had kept his bed eight years, and was sick of the palsy."},
  {id: 327, ref: "Act9:34", verse: "And Peter said unto him, Aeneas, Jesus Christ maketh thee whole: arise, and make thy bed. And he arose immediately."},
  {id: 328, ref: "Act9:35", verse: "And all that dwelt at Lydda and Saron saw him, and turned to the Lord."},
  {id: 329, ref: "Act9:36", verse: "Now there was at Joppa a certain disciple named Tabitha, which by interpretation is called Dorcas: this woman was full of good works and almsdeeds which she did."},
  {id: 330, ref: "Act9:37", verse: "And it came to pass in those days, that she was sick, and died: whom when they had washed, they laid her in an upper chamber."},
  {id: 331, ref: "Act9:38", verse: "And forasmuch as Lydda was nigh to Joppa, and the disciples had heard that Peter was there, they sent unto him two men, desiring him that he would not delay to come to them."},
  {id: 332, ref: "Act9:39", verse: "Then Peter arose and went with them. When he was come, they brought him into the upper chamber: and all the widows stood by him weeping, and shewing the coats and garments which Dorcas made, while she was with them."},
  {id: 333, ref: "Act9:40", verse: "But Peter put them all forth, and kneeled down, and prayed; and turning him to the body said, Tabitha, arise. And she opened her eyes: and when she saw Peter, she sat up."},
  {id: 334, ref: "Act9:41", verse: "And he gave her his hand, and lifted her up, and when he had called the saints and widows, presented her alive."},
  {id: 335, ref: "Act9:42", verse: "And it was known throughout all Joppa; and many believed in the Lord."},
  {id: 336, ref: "Act9:43", verse: "And it came to pass, that he tarried many days in Joppa with one Simon a tanner."},
  {id: 337, ref: "Act10:1", verse: "There was a certain man in Caesarea called Cornelius, a centurion of the band called the Italian band,"},
  {id: 338, ref: "Act10:2", verse: "A devout man, and one that feared God with all his house, which gave much alms to the people, and prayed to God alway."},
  {id: 339, ref: "Act10:3", verse: "He saw in a vision evidently about the ninth hour of the day an angel of God coming in to him, and saying unto him, Cornelius."},
  {id: 340, ref: "Act10:4", verse: "And when he looked on him, he was afraid, and said, What is it, Lord? And he said unto him, Thy prayers and thine alms are come up for a memorial before God."},
  {id: 341, ref: "Act10:5", verse: "And now send men to Joppa, and call for one Simon, whose surname is Peter:"},
  {id: 342, ref: "Act10:6", verse: "He lodgeth with one Simon a tanner, whose house is by the sea side: he shall tell thee what thou oughtest to do."},
  {id: 343, ref: "Act10:7", verse: "And when the angel which spake unto Cornelius was departed, he called two of his household servants, and a devout soldier of them that waited on him continually;"},
  {id: 344, ref: "Act10:8", verse: "And when he had declared all these things unto them, he sent them to Joppa."},
  {id: 345, ref: "Act10:9", verse: "On the morrow, as they went on their journey, and drew nigh unto the city, Peter went up upon the housetop to pray about the sixth hour:"},
  {id: 346, ref: "Act10:10", verse: "And he became very hungry, and would have eaten: but while they made ready, he fell into a trance,"},
  {id: 347, ref: "Act10:11", verse: "And saw heaven opened, and a certain vessel descending unto him, as it had been a great sheet knit at the four corners, and let down to the earth:"},
  {id: 348, ref: "Act10:12", verse: "Wherein were all manner of fourfooted beasts of the earth, and wild beasts, and creeping things, and fowls of the air."},
  {id: 349, ref: "Act10:13", verse: "And there came a voice to him, Rise, Peter; kill, and eat."},
  {id: 350, ref: "Act10:14", verse: "But Peter said, Not so, Lord; for I have never eaten any thing that is common or unclean."},
  {id: 351, ref: "Act10:15", verse: "And the voice spake unto him again the second time, What God hath cleansed, that call not thou common."},
  {id: 352, ref: "Act10:16", verse: "This was done thrice: and the vessel was received up again into heaven."},
  {id: 353, ref: "Act10:17", verse: "Now while Peter doubted in himself what this vision which he had seen should mean, behold, the men which were sent from Cornelius had made enquiry for Simon's house, and stood before the gate,"},
  {id: 354, ref: "Act10:18", verse: "And called, and asked whether Simon, which was surnamed Peter, were lodged there."},
  {id: 355, ref: "Act10:19", verse: "While Peter thought on the vision, the Spirit said unto him, Behold, three men seek thee."},
  {id: 356, ref: "Act10:20", verse: "Arise therefore, and get thee down, and go with them, doubting nothing: for I have sent them."},
  {id: 357, ref: "Act10:21", verse: "Then Peter went down to the men which were sent unto him from Cornelius; and said, Behold, I am he whom ye seek: what is the cause wherefore ye are come?"},
  {id: 358, ref: "Act10:22", verse: "And they said, Cornelius the centurion, a just man, and one that feareth God, and of good report among all the nation of the Jews, was warned from God by an holy angel to send for thee into his house, and to hear words of thee."},
  {id: 359, ref: "Act10:23", verse: "Then called he them in, and lodged them. And on the morrow Peter went away with them, and certain brethren from Joppa accompanied him."},
  {id: 360, ref: "Act10:24", verse: "And the morrow after they entered into Caesarea. And Cornelius waited for them, and had called together his kinsmen and near friends."},
  {id: 361, ref: "Act10:25", verse: "And as Peter was coming in, Cornelius met him, and fell down at his feet, and worshipped him."},
  {id: 362, ref: "Act10:26", verse: "But Peter took him up, saying, Stand up; I myself also am a man."},
  {id: 363, ref: "Act10:27", verse: "And as he talked with him, he went in, and found many that were come together."},
  {id: 364, ref: "Act10:28", verse: "And he said unto them, Ye know how that it is an unlawful thing for a man that is a Jew to keep company, or come unto one of another nation; but God hath shewed me that I should not call any man common or unclean."},
  {id: 365, ref: "Act10:29", verse: "Therefore came I unto you without gainsaying, as soon as I was sent for: I ask therefore for what intent ye have sent for me?"},
  {id: 366, ref: "Act10:30", verse: "And Cornelius said, Four days ago I was fasting until this hour; and at the ninth hour I prayed in my house, and, behold, a man stood before me in bright clothing,"},
  {id: 367, ref: "Act10:31", verse: "And said, Cornelius, thy prayer is heard, and thine alms are had in remembrance in the sight of God."},
  {id: 368, ref: "Act10:32", verse: "Send therefore to Joppa, and call hither Simon, whose surname is Peter; he is lodged in the house of one Simon a tanner by the sea side: who, when he cometh, shall speak unto thee."},
  {id: 369, ref: "Act10:33", verse: "Immediately therefore I sent to thee; and thou hast well done that thou art come. Now therefore are we all here present before God, to hear all things that are commanded thee of God."},
  {id: 370, ref: "Act10:34", verse: "Then Peter opened his mouth, and said, Of a truth I perceive that God is no respecter of persons:"},
  {id: 371, ref: "Act10:35", verse: "But in every nation he that feareth him, and worketh righteousness, is accepted with him."},
  {id: 372, ref: "Act10:36", verse: "The word which God sent unto the children of Israel, preaching peace by Jesus Christ: (he is Lord of all:)"},
  {id: 373, ref: "Act10:37", verse: "That word, I say, ye know, which was published throughout all Judaea, and began from Galilee, after the baptism which John preached;"},
  {id: 374, ref: "Act10:38", verse: "How God anointed Jesus of Nazareth with the Holy Ghost and with power: who went about doing good, and healing all that were oppressed of the devil; for God was with him."},
  {id: 375, ref: "Act10:39", verse: "And we are witnesses of all things which he did both in the land of the Jews, and in Jerusalem; whom they slew and hanged on a tree:"},
  {id: 376, ref: "Act10:40", verse: "Him God raised up the third day, and shewed him openly;"},
  {id: 377, ref: "Act10:41", verse: "Not to all the people, but unto witnesses chosen before of God, even to us, who did eat and drink with him after he rose from the dead."},
  {id: 378, ref: "Act10:42", verse: "And he commanded us to preach unto the people, and to testify that it is he which was ordained of God to be the Judge of quick and dead."},
  {id: 379, ref: "Act10:43", verse: "To him give all the prophets witness, that through his name whosoever believeth in him shall receive remission of sins."},
  {id: 380, ref: "Act10:44", verse: "While Peter yet spake these words, the Holy Ghost fell on all them which heard the word."},
  {id: 381, ref: "Act10:45", verse: "And they of the circumcision which believed were astonished, as many as came with Peter, because that on the Gentiles also was poured out the gift of the Holy Ghost."},
  {id: 382, ref: "Act10:46", verse: "For they heard them speak with tongues, and magnify God. Then answered Peter,"},
  {id: 383, ref: "Act10:47", verse: "Can any man forbid water, that these should not be baptized, which have received the Holy Ghost as well as we?"},
  {id: 384, ref: "Act10:48", verse: "And he commanded them to be baptized in the name of the Lord. Then prayed they him to tarry certain days."},
  {id: 385, ref: "Act11:1", verse: "And the apostles and brethren that were in Judaea heard that the Gentiles had also received the word of God."},
  {id: 386, ref: "Act11:2", verse: "And when Peter was come up to Jerusalem, they that were of the circumcision contended with him,"},
  {id: 387, ref: "Act11:3", verse: "Saying, Thou wentest in to men uncircumcised, and didst eat with them."},
  {id: 388, ref: "Act11:4", verse: "But Peter rehearsed the matter from the beginning, and expounded it by order unto them, saying,"},
  {id: 389, ref: "Act11:5", verse: "I was in the city of Joppa praying: and in a trance I saw a vision, A certain vessel descend, as it had been a great sheet, let down from heaven by four corners; and it came even to me:"},
  {id: 390, ref: "Act11:6", verse: "Upon the which when I had fastened mine eyes, I considered, and saw fourfooted beasts of the earth, and wild beasts, and creeping things, and fowls of the air."},
  {id: 391, ref: "Act11:7", verse: "And I heard a voice saying unto me, Arise, Peter; slay and eat."},
  {id: 392, ref: "Act11:8", verse: "But I said, Not so, Lord: for nothing common or unclean hath at any time entered into my mouth."},
  {id: 393, ref: "Act11:9", verse: "But the voice answered me again from heaven, What God hath cleansed, that call not thou common."},
  {id: 394, ref: "Act11:10", verse: "And this was done three times: and all were drawn up again into heaven."},
  {id: 395, ref: "Act11:11", verse: "And, behold, immediately there were three men already come unto the house where I was, sent from Caesarea unto me."},
  {id: 396, ref: "Act11:12", verse: "And the Spirit bade me go with them, nothing doubting. Moreover these six brethren accompanied me, and we entered into the man's house:"},
  {id: 397, ref: "Act11:13", verse: "And he shewed us how he had seen an angel in his house, which stood and said unto him, Send men to Joppa, and call for Simon, whose surname is Peter;"},
  {id: 398, ref: "Act11:14", verse: "Who shall tell thee words, whereby thou and all thy house shall be saved."},
  {id: 399, ref: "Act11:15", verse: "And as I began to speak, the Holy Ghost fell on them, as on us at the beginning."},
  {id: 400, ref: "Act11:16", verse: "Then remembered I the word of the Lord, how that he said, John indeed baptized with water; but ye shall be baptized with the Holy Ghost."},
  {id: 401, ref: "Act11:17", verse: "Forasmuch then as God gave them the like gift as he did unto us, who believed on the Lord Jesus Christ; what was I, that I could withstand God?"},
  {id: 402, ref: "Act11:18", verse: "When they heard these things, they held their peace, and glorified God, saying, Then hath God also to the Gentiles granted repentance unto life."},
  {id: 403, ref: "Act11:19", verse: "Now they which were scattered abroad upon the persecution that arose about Stephen travelled as far as Phenice, and Cyprus, and Antioch, preaching the word to none but unto the Jews only."},
  {id: 404, ref: "Act11:20", verse: "And some of them were men of Cyprus and Cyrene, which, when they were come to Antioch, spake unto the Grecians, preaching the Lord Jesus."},
  {id: 405, ref: "Act11:21", verse: "And the hand of the Lord was with them: and a great number believed, and turned unto the Lord."},
  {id: 406, ref: "Act11:22", verse: "Then tidings of these things came unto the ears of the church which was in Jerusalem: and they sent forth Barnabas, that he should go as far as Antioch."},
  {id: 407, ref: "Act11:23", verse: "Who, when he came, and had seen the grace of God, was glad, and exhorted them all, that with purpose of heart they would cleave unto the Lord."},
  {id: 408, ref: "Act11:24", verse: "For he was a good man, and full of the Holy Ghost and of faith: and much people was added unto the Lord."},
  {id: 409, ref: "Act11:25", verse: "Then departed Barnabas to Tarsus, for to seek Saul:"},
  {id: 410, ref: "Act11:26", verse: "And when he had found him, he brought him unto Antioch. And it came to pass, that a whole year they assembled themselves with the church, and taught much people. And the disciples were called Christians first in Antioch."},
  {id: 411, ref: "Act11:27", verse: "And in these days came prophets from Jerusalem unto Antioch."},
  {id: 412, ref: "Act11:28", verse: "And there stood up one of them named Agabus, and signified by the Spirit that there should be great dearth throughout all the world: which came to pass in the days of Claudius Caesar."},
  {id: 413, ref: "Act11:29", verse: "Then the disciples, every man according to his ability, determined to send relief unto the brethren which dwelt in Judaea:"},
  {id: 414, ref: "Act11:30", verse: "Which also they did, and sent it to the elders by the hands of Barnabas and Saul."},
  {id: 415, ref: "Act12:1", verse: "Now about that time Herod the king stretched forth his hands to vex certain of the church."},
  {id: 416, ref: "Act12:2", verse: "And he killed James the brother of John with the sword."},
  {id: 417, ref: "Act12:3", verse: "And because he saw it pleased the Jews, he proceeded further to take Peter also. (Then were the days of unleavened bread.)"},
  {id: 418, ref: "Act12:4", verse: "And when he had apprehended him, he put him in prison, and delivered him to four quaternions of soldiers to keep him; intending after Easter to bring him forth to the people."},
  {id: 419, ref: "Act12:5", verse: "Peter therefore was kept in prison: but prayer was made without ceasing of the church unto God for him."},
  {id: 420, ref: "Act12:6", verse: "And when Herod would have brought him forth, the same night Peter was sleeping between two soldiers, bound with two chains: and the keepers before the door kept the prison."},
  {id: 421, ref: "Act12:7", verse: "And, behold, the angel of the Lord came upon him, and a light shined in the prison: and he smote Peter on the side, and raised him up, saying, Arise up quickly. And his chains fell off from his hands."},
  {id: 422, ref: "Act12:8", verse: "And the angel said unto him, Gird thyself, and bind on thy sandals. And so he did. And he saith unto him, Cast thy garment about thee, and follow me."},
  {id: 423, ref: "Act12:9", verse: "And he went out, and followed him; and wist not that it was true which was done by the angel; but thought he saw a vision."},
  {id: 424, ref: "Act12:10", verse: "When they were past the first and the second ward, they came unto the iron gate that leadeth unto the city; which opened to them of his own accord: and they went out, and passed on through one street; and forthwith the angel departed from him."},
  {id: 425, ref: "Act12:11", verse: "And when Peter was come to himself, he said, Now I know of a surety, that the Lord hath sent his angel, and hath delivered me out of the hand of Herod, and from all the expectation of the people of the Jews."},
  {id: 426, ref: "Act12:12", verse: "And when he had considered the thing, he came to the house of Mary the mother of John, whose surname was Mark; where many were gathered together praying."},
  {id: 427, ref: "Act12:13", verse: "And as Peter knocked at the door of the gate, a damsel came to hearken, named Rhoda."},
  {id: 428, ref: "Act12:14", verse: "And when she knew Peter's voice, she opened not the gate for gladness, but ran in, and told how Peter stood before the gate."},
  {id: 429, ref: "Act12:15", verse: "And they said unto her, Thou art mad. But she constantly affirmed that it was even so. Then said they, It is his angel."},
  {id: 430, ref: "Act12:16", verse: "But Peter continued knocking: and when they had opened the door, and saw him, they were astonished."},
  {id: 431, ref: "Act12:17", verse: "But he, beckoning unto them with the hand to hold their peace, declared unto them how the Lord had brought him out of the prison. And he said, Go shew these things unto James, and to the brethren. And he departed, and went into another place."},
  {id: 432, ref: "Act12:18", verse: "Now as soon as it was day, there was no small stir among the soldiers, what was become of Peter."},
  {id: 433, ref: "Act12:19", verse: "And when Herod had sought for him, and found him not, he examined the keepers, and commanded that they should be put to death. And he went down from Judaea to Caesarea, and there abode."},
  {id: 434, ref: "Act12:20", verse: "And Herod was highly displeased with them of Tyre and Sidon: but they came with one accord to him, and, having made Blastus the king's chamberlain their friend, desired peace; because their country was nourished by the king's country."},
  {id: 435, ref: "Act12:21", verse: "And upon a set day Herod, arrayed in royal apparel, sat upon his throne, and made an oration unto them."},
  {id: 436, ref: "Act12:22", verse: "And the people gave a shout, saying, It is the voice of a god, and not of a man."},
  {id: 437, ref: "Act12:23", verse: "And immediately the angel of the Lord smote him, because he gave not God the glory: and he was eaten of worms, and gave up the ghost."},
  {id: 438, ref: "Act12:24", verse: "But the word of God grew and multiplied."},
  {id: 439, ref: "Act12:25", verse: "And Barnabas and Saul returned from Jerusalem, when they had fulfilled their ministry, and took with them John, whose surname was Mark."},
  {id: 440, ref: "Act13:1", verse: "Now there were in the church that was at Antioch certain prophets and teachers; as Barnabas, and Simeon that was called Niger, and Lucius of Cyrene, and Manaen, which had been brought up with Herod the tetrarch, and Saul."},
  {id: 441, ref: "Act13:2", verse: "As they ministered to the Lord, and fasted, the Holy Ghost said, Separate me Barnabas and Saul for the work whereunto I have called them."},
  {id: 442, ref: "Act13:3", verse: "And when they had fasted and prayed, and laid their hands on them, they sent them away."},
  {id: 443, ref: "Act13:4", verse: "So they, being sent forth by the Holy Ghost, departed unto Seleucia; and from thence they sailed to Cyprus."},
  {id: 444, ref: "Act13:5", verse: "And when they were at Salamis, they preached the word of God in the synagogues of the Jews: and they had also John to their minister."},
  {id: 445, ref: "Act13:6", verse: "And when they had gone through the isle unto Paphos, they found a certain sorcerer, a false prophet, a Jew, whose name was Barjesus:"},
  {id: 446, ref: "Act13:7", verse: "Which was with the deputy of the country, Sergius Paulus, a prudent man; who called for Barnabas and Saul, and desired to hear the word of God."},
  {id: 447, ref: "Act13:8", verse: "But Elymas the sorcerer (for so is his name by interpretation) withstood them, seeking to turn away the deputy from the faith."},
  {id: 448, ref: "Act13:9", verse: "Then Saul, (who also is called Paul,) filled with the Holy Ghost, set his eyes on him,"},
  {id: 449, ref: "Act13:10", verse: "And said, O full of all subtilty and all mischief, thou child of the devil, thou enemy of all righteousness, wilt thou not cease to pervert the right ways of the Lord?"},
  {id: 450, ref: "Act13:11", verse: "And now, behold, the hand of the Lord is upon thee, and thou shalt be blind, not seeing the sun for a season. And immediately there fell on him a mist and a darkness; and he went about seeking some to lead him by the hand."},
  {id: 451, ref: "Act13:12", verse: "Then the deputy, when he saw what was done, believed, being astonished at the doctrine of the Lord."},
  {id: 452, ref: "Act13:13", verse: "Now when Paul and his company loosed from Paphos, they came to Perga in Pamphylia: and John departing from them returned to Jerusalem."},
  {id: 453, ref: "Act13:14", verse: "But when they departed from Perga, they came to Antioch in Pisidia, and went into the synagogue on the sabbath day, and sat down."},
  {id: 454, ref: "Act13:15", verse: "And after the reading of the law and the prophets the rulers of the synagogue sent unto them, saying, Ye men and brethren, if ye have any word of exhortation for the people, say on."},
  {id: 455, ref: "Act13:16", verse: "Then Paul stood up, and beckoning with his hand said, Men of Israel, and ye that fear God, give audience."},
  {id: 456, ref: "Act13:17", verse: "The God of this people of Israel chose our fathers, and exalted the people when they dwelt as strangers in the land of Egypt, and with an high arm brought he them out of it."},
  {id: 457, ref: "Act13:18", verse: "And about the time of forty years suffered he their manners in the wilderness."},
  {id: 458, ref: "Act13:19", verse: "And when he had destroyed seven nations in the land of Chanaan, he divided their land to them by lot."},
  {id: 459, ref: "Act13:20", verse: "And after that he gave unto them judges about the space of four hundred and fifty years, until Samuel the prophet."},
  {id: 460, ref: "Act13:21", verse: "And afterward they desired a king: and God gave unto them Saul the son of Cis, a man of the tribe of Benjamin, by the space of forty years."},
  {id: 461, ref: "Act13:22", verse: "And when he had removed him, he raised up unto them David to be their king; to whom also he gave testimony, and said, I have found David the son of Jesse, a man after mine own heart, which shall fulfil all my will."},
  {id: 462, ref: "Act13:23", verse: "Of this man's seed hath God according to his promise raised unto Israel a Saviour, Jesus:"},
  {id: 463, ref: "Act13:24", verse: "When John had first preached before his coming the baptism of repentance to all the people of Israel."},
  {id: 464, ref: "Act13:25", verse: "And as John fulfilled his course, he said, Whom think ye that I am? I am not he. But, behold, there cometh one after me, whose shoes of his feet I am not worthy to loose."},
  {id: 465, ref: "Act13:26", verse: "Men and brethren, children of the stock of Abraham, and whosoever among you feareth God, to you is the word of this salvation sent."},
  {id: 466, ref: "Act13:27", verse: "For they that dwell at Jerusalem, and their rulers, because they knew him not, nor yet the voices of the prophets which are read every sabbath day, they have fulfilled them in condemning him."},
  {id: 467, ref: "Act13:28", verse: "And though they found no cause of death in him, yet desired they Pilate that he should be slain."},
  {id: 468, ref: "Act13:29", verse: "And when they had fulfilled all that was written of him, they took him down from the tree, and laid him in a sepulchre."},
  {id: 469, ref: "Act13:30", verse: "But God raised him from the dead:"},
  {id: 470, ref: "Act13:31", verse: "And he was seen many days of them which came up with him from Galilee to Jerusalem, who are his witnesses unto the people."},
  {id: 471, ref: "Act13:32", verse: "And we declare unto you glad tidings, how that the promise which was made unto the fathers,"},
  {id: 472, ref: "Act13:33", verse: "God hath fulfilled the same unto us their children, in that he hath raised up Jesus again; as it is also written in the second psalm, Thou art my Son, this day have I begotten thee."},
  {id: 473, ref: "Act13:34", verse: "And as concerning that he raised him up from the dead, now no more to return to corruption, he said on this wise, I will give you the sure mercies of David."},
  {id: 474, ref: "Act13:35", verse: "Wherefore he saith also in another psalm, Thou shalt not suffer thine Holy One to see corruption."},
  {id: 475, ref: "Act13:36", verse: "For David, after he had served his own generation by the will of God, fell on sleep, and was laid unto his fathers, and saw corruption:"},
  {id: 476, ref: "Act13:37", verse: "But he, whom God raised again, saw no corruption."},
  {id: 477, ref: "Act13:38", verse: "Be it known unto you therefore, men and brethren, that through this man is preached unto you the forgiveness of sins:"},
  {id: 478, ref: "Act13:39", verse: "And by him all that believe are justified from all things, from which ye could not be justified by the law of Moses."},
  {id: 479, ref: "Act13:40", verse: "Beware therefore, lest that come upon you, which is spoken of in the prophets;"},
  {id: 480, ref: "Act13:41", verse: "Behold, ye despisers, and wonder, and perish: for I work a work in your days, a work which ye shall in no wise believe, though a man declare it unto you."},
  {id: 481, ref: "Act13:42", verse: "And when the Jews were gone out of the synagogue, the Gentiles besought that these words might be preached to them the next sabbath."},
  {id: 482, ref: "Act13:43", verse: "Now when the congregation was broken up, many of the Jews and religious proselytes followed Paul and Barnabas: who, speaking to them, persuaded them to continue in the grace of God."},
  {id: 483, ref: "Act13:44", verse: "And the next sabbath day came almost the whole city together to hear the word of God."},
  {id: 484, ref: "Act13:45", verse: "But when the Jews saw the multitudes, they were filled with envy, and spake against those things which were spoken by Paul, contradicting and blaspheming."},
  {id: 485, ref: "Act13:46", verse: "Then Paul and Barnabas waxed bold, and said, It was necessary that the word of God should first have been spoken to you: but seeing ye put it from you, and judge yourselves unworthy of everlasting life, lo, we turn to the Gentiles."},
  {id: 486, ref: "Act13:47", verse: "For so hath the Lord commanded us, saying, I have set thee to be a light of the Gentiles, that thou shouldest be for salvation unto the ends of the earth."},
  {id: 487, ref: "Act13:48", verse: "And when the Gentiles heard this, they were glad, and glorified the word of the Lord: and as many as were ordained to eternal life believed."},
  {id: 488, ref: "Act13:49", verse: "And the word of the Lord was published throughout all the region."},
  {id: 489, ref: "Act13:50", verse: "But the Jews stirred up the devout and honourable women, and the chief men of the city, and raised persecution against Paul and Barnabas, and expelled them out of their coasts."},
  {id: 490, ref: "Act13:51", verse: "But they shook off the dust of their feet against them, and came unto Iconium."},
  {id: 491, ref: "Act13:52", verse: "And the disciples were filled with joy, and with the Holy Ghost."},
  {id: 492, ref: "Act14:1", verse: "And it came to pass in Iconium, that they went both together into the synagogue of the Jews, and so spake, that a great multitude both of the Jews and also of the Greeks believed."},
  {id: 493, ref: "Act14:2", verse: "But the unbelieving Jews stirred up the Gentiles, and made their minds evil affected against the brethren."},
  {id: 494, ref: "Act14:3", verse: "Long time therefore abode they speaking boldly in the Lord, which gave testimony unto the word of his grace, and granted signs and wonders to be done by their hands."},
  {id: 495, ref: "Act14:4", verse: "But the multitude of the city was divided: and part held with the Jews, and part with the apostles."},
  {id: 496, ref: "Act14:5", verse: "And when there was an assault made both of the Gentiles, and also of the Jews with their rulers, to use them despitefully, and to stone them,"},
  {id: 497, ref: "Act14:6", verse: "They were ware of it, and fled unto Lystra and Derbe, cities of Lycaonia, and unto the region that lieth round about:"},
  {id: 498, ref: "Act14:7", verse: "And there they preached the gospel."},
  {id: 499, ref: "Act14:8", verse: "And there sat a certain man at Lystra, impotent in his feet, being a cripple from his mother's womb, who never had walked:"},
  {id: 500, ref: "Act14:9", verse: "The same heard Paul speak: who stedfastly beholding him, and perceiving that he had faith to be healed,"},
  {id: 501, ref: "Act14:10", verse: "Said with a loud voice, Stand upright on thy feet. And he leaped and walked."},
  {id: 502, ref: "Act14:11", verse: "And when the people saw what Paul had done, they lifted up their voices, saying in the speech of Lycaonia, The gods are come down to us in the likeness of men."},
  {id: 503, ref: "Act14:12", verse: "And they called Barnabas, Jupiter; and Paul, Mercurius, because he was the chief speaker."},
  {id: 504, ref: "Act14:13", verse: "Then the priest of Jupiter, which was before their city, brought oxen and garlands unto the gates, and would have done sacrifice with the people."},
  {id: 505, ref: "Act14:14", verse: "Which when the apostles, Barnabas and Paul, heard of, they rent their clothes, and ran in among the people, crying out,"},
  {id: 506, ref: "Act14:15", verse: "And saying, Sirs, why do ye these things? We also are men of like passions with you, and preach unto you that ye should turn from these vanities unto the living God, which made heaven, and earth, and the sea, and all things that are therein:"},
  {id: 507, ref: "Act14:16", verse: "Who in times past suffered all nations to walk in their own ways."},
  {id: 508, ref: "Act14:17", verse: "Nevertheless he left not himself without witness, in that he did good, and gave us rain from heaven, and fruitful seasons, filling our hearts with food and gladness."},
  {id: 509, ref: "Act14:18", verse: "And with these sayings scarce restrained they the people, that they had not done sacrifice unto them."},
  {id: 510, ref: "Act14:19", verse: "And there came thither certain Jews from Antioch and Iconium, who persuaded the people, and, having stoned Paul, drew him out of the city, supposing he had been dead."},
  {id: 511, ref: "Act14:20", verse: "Howbeit, as the disciples stood round about him, he rose up, and came into the city: and the next day he departed with Barnabas to Derbe."},
  {id: 512, ref: "Act14:21", verse: "And when they had preached the gospel to that city, and had taught many, they returned again to Lystra, and to Iconium, and Antioch,"},
  {id: 513, ref: "Act14:22", verse: "Confirming the souls of the disciples, and exhorting them to continue in the faith, and that we must through much tribulation enter into the kingdom of God."},
  {id: 514, ref: "Act14:23", verse: "And when they had ordained them elders in every church, and had prayed with fasting, they commended them to the Lord, on whom they believed."},
  {id: 515, ref: "Act14:24", verse: "And after they had passed throughout Pisidia, they came to Pamphylia."},
  {id: 516, ref: "Act14:25", verse: "And when they had preached the word in Perga, they went down into Attalia:"},
  {id: 517, ref: "Act14:26", verse: "And thence sailed to Antioch, from whence they had been recommended to the grace of God for the work which they fulfilled."},
  {id: 518, ref: "Act14:27", verse: "And when they were come, and had gathered the church together, they rehearsed all that God had done with them, and how he had opened the door of faith unto the Gentiles."},
  {id: 519, ref: "Act14:28", verse: "And there they abode long time with the disciples."}
];

export const getSrverses = () => srverses;
